import { User } from 'firebase/auth';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import FirebaseStore from './firebaseStore';
import { Collections } from './dbStore';
import { AuthErrorCodesEx } from '../constants/authErrors';
import { IProfile, IUserInfo, IUserGroups, IUserBio, IBankAccount, IServiceAgreementConsent } from 'realhaus-sdk';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';
import { AnalyticsStore } from './analyticsStore';

export interface ISignupUser {
  firstname: string;
  lastname: string;
  email: string;
  passwordOne: string;
  passwordTwo: string;
}

export interface IAccountSetupSteps {
  USER_PROFILE_COMPLETE: boolean;
  EMAIL_VERIFIED: boolean;
  TERMS_OF_USE_SIGNED: boolean;
  IDENTITY_VERIFIED: boolean;
  PAYOUT_ACCT_SETUP?: boolean;
}

export enum IAuthStatus {
  UNKNOWN,
  SIGNEDIN,
  NOTSIGNEDIN
}

export class UserStore {
  listener: any;
  @observable userId?: string;
  @observable userProfile: IProfile | undefined;
  @observable authStatus: IAuthStatus = IAuthStatus.UNKNOWN;

  constructor(private fireStore: FirebaseStore, private analyticsStore: AnalyticsStore) {
    makeAutoObservable(this)

    this.listener = this.fireStore.onAuthUserListener(
      async (user: any) => {
        if (!user.emailVerified) {
          console.log('Email has not been verified');
          // this.logout();
          return;
        } else {
          this.authStatus = !!user ? IAuthStatus.SIGNEDIN : IAuthStatus.NOTSIGNEDIN;
          localStorage.setItem('authUser', JSON.stringify(this.firebaseUserToUserInfo(user)));
          runInAction(() => {
            this.userId = user.uid;
          });
          await this.updateUserProfile();
          analyticsStore.trackLogin();
        }
      },
      () => {
        this.authStatus = IAuthStatus.NOTSIGNEDIN;

        localStorage.removeItem('authUser');
        console.log("Remove userInfo - Listener");
        runInAction(() => {
          this.userId = undefined;
        });
      });
  }

  private firebaseUserToUserInfo = (fbUser: any) => {
    return {
      uid: fbUser.uid,
      username: fbUser.firstname + ' ' + fbUser.lastname,
      email: fbUser.email,
      photoURL: fbUser.providerData[0].photoURL,
      phoneNumber: fbUser.providerData[0].phoneNumber
    }
  }

  @action
  private updateUserProfile = async () => {
    let userProfile = await this.getProfile() || {} as IProfile;
    // NOTE: this makes sure the user's profile image is stored in the users collection
    // this was done as there's no other way for a user to view other's profile image
    console.log('syncing profile image');
    if (this.userInfo && this.userInfo.photoURL
      && this.userInfo.photoURL !== this.userProfile?.photoURL) {
      const uid = this.fireStore.authService.currentUser?.uid;
      if (!uid) return;
      userProfile = { ...userProfile, photoURL: this.userInfo.photoURL };
      await this.fireStore.updateUser(uid, userProfile);
    }

    this.userProfile = userProfile;
    this.analyticsStore.updateUserProps(userProfile.gender, userProfile.dateOfBirth);
  }

  @computed
  get isAuthenticated() {
    return !!this.fireStore.authService.currentUser;
  }

  @computed
  get isAuthReady() {
    return this.authStatus !== IAuthStatus.UNKNOWN;
  }

  get userInfo(): IUserInfo | undefined {
    const cachedInfo = localStorage.getItem('authUser');

    if (cachedInfo) {
      const uInfo = JSON.parse(cachedInfo) as IUserInfo;
      if (uInfo.uid === this.userId)
        return uInfo;
    }

    return undefined;
  }

  getProfile = async () => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    var userRef = await this.fireStore.user(uid);

    if (userRef.exists()) {
      const userProfile = userRef.data() as IProfile;
      return userProfile;
    }
  }

  getAccountSetupSteps = async () => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const userRef = await this.fireStore.user(uid);
    const userProfile = userRef.data() as IProfile;
    const bankAccounts = await (await this.fireStore.getDocument(Collections.bankAccounts, uid)).data() as IBankAccount;
    const allBankAccts = [...(bankAccounts?.payouts ?? []), ...(bankAccounts?.payments ?? [])];
    return {
      USER_PROFILE_COMPLETE: !!userProfile?.firstname && !!userProfile?.phoneNumber && !!userProfile?.lastname && !!userProfile?.gender,
      EMAIL_VERIFIED: this.fireStore.authService.currentUser?.emailVerified,
      TERMS_OF_USE_SIGNED: false,
      IDENTITY_VERIFIED: userProfile?.isVerified,
      PAYOUT_ACCT_SETUP: allBankAccts.length > 0,
    } as IAccountSetupSteps
  }

  getUserProfile = async (uid: string) => {
    if (!uid) return;

    try {
      var userRef = await this.fireStore.user(uid);

      if (userRef.exists()) {
        const userProfile = userRef.data() as IProfile;
        return userProfile;
      }
    } catch (err) {
      console.error(`Error getting userProfile for user ${uid}`, err)
    }
  }

  @action
  updateProfile = async (profile: IProfile) => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    await this.fireStore.updateUser(uid, profile);
    await this.updateUserProfile();
  }

  updateProfilePicture = async (photo: File) => {
    if (!photo) {
      return;
    }

    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      return;
    }
    const basePath = `userdata/${uid}/profile/displaypicture`
    const promise = this.fireStore.uploadFile(photo, basePath);
    const downloadPath = await Promise.resolve(promise)
    await this.fireStore.doProfilePhotoUpdate(downloadPath);

    return downloadPath;
  }

  @action
  login = async (email: string, password: string) => {
    console.log("Remove userInfo - Login");
    this.userId = undefined;
    localStorage.removeItem('authUser');

    const cred = await this.fireStore.signInWithEmailAndPassword(email, password);
    if (!cred.user.emailVerified) {
      const err = { code: 'email-not-verified', message: 'Email address is not verified', user: cred.user };
      this.logout();
      throw err;
    }

    this.analyticsStore.updateAnalyticsId(email);
  }

  @action
  sendVerificationLink = async (user: User) => {
    // Send email verification link
    await this.fireStore.doSendEmailVerification(user);
  }

  @action
  logout = async () => {
    this.authStatus = IAuthStatus.NOTSIGNEDIN;
    localStorage.removeItem('authUser');
    this.userId = undefined;

    await this.fireStore.signOut();
  }

  updatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const reauthenticatePassword = await this.fireStore.reauthenticatePassword(oldPassword)
      if (!reauthenticatePassword.user) {
        const err = { code: AuthErrorCodesEx.USER_NOT_AUTHORIZED };
        throw err;
      }
      await this.fireStore.doPasswordUpdate(newPassword);
    } catch (err: any) {
      if (err?.code === AuthErrorCodesEx.USER_NOT_AUTHORIZED) {
        this.logout();
      }
      throw err;
    }
  }

  passwordReset = async (email: string) => {
    try {
      await this.fireStore.doPasswordReset(email)
    } catch (err: any) {
      if (err.code === 'auth/user-not-found') {
        console.log('User not found')
      }
      throw err;
    }
  }

  create = async (user: ISignupUser) => {
    try {
      if (!user || !user.firstname || !user.lastname) { return; }

      console.log('Creating user');
      const resp = await this.fireStore.createUserWithEmailAndPassword(user.email, user.passwordOne);

      const uid = resp.user?.uid;
      if (!uid) { return; }

      this.analyticsStore.trackSignUp();
      var userProfile = {
        firstname: user.firstname,
        lastname: user.lastname
      } as IUserBio;

      await this.fireStore.updateUser(uid, userProfile);
      await this.fireStore.doSendEmailVerification(resp.user);

      this.logout();
      return Promise.resolve(resp);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  getUserGroups = async (): Promise<IUserGroups | undefined> => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    let ref = await this.fireStore.getDocument(Collections.userGroups, uid);
    if (!ref.exists()) {
      // create group
      const group = {
        conversations: []
      } as IUserGroups;
      await this.fireStore.addDocumentWithId(Collections.userGroups, uid, group);
      ref = await this.fireStore.getDocument(Collections.userGroups, uid);
    }

    return ref.data() as IUserGroups;
  }

  updateUserGroup = async (userGroups: IUserGroups) => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;
    await this.fireStore.updateDocument(Collections.userGroups, uid, userGroups);
  }

  addConversationGroup = async (convoId: string, uid: string): Promise<void> => {

    if (!uid || !convoId) return;

    let ref = await this.fireStore.getDocument(Collections.userGroups, uid);

    const groups = ref.data() as IUserGroups;
    groups.conversations = Array.from(new Set([...(groups.conversations ?? []), convoId]));

    await this.fireStore.updateDocument(Collections.userGroups, uid, groups);
  }

  emailUpdate = async (newEmail: string): Promise<void> => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('You do not have permission to request an email update');
    }
    await this.fireStore.doEmailUpdate(newEmail);
    this.logout();
  }

  getUserToken = async () => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in!');
    }
    return await this.fireStore.authService.currentUser?.getIdToken();
  }

  requiresConsent = async () => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const serviceConsentData = (await this.fireStore.getDocument(Collections.backgroundCheckConsent, uid)).data() as IServiceAgreementConsent
    if (!!serviceConsentData) {

      const signedDate = new Date(serviceConsentData.timeStamp);
      signedDate.setMonth(signedDate.getMonth() + 6); //consent expiry date in 6 months

      const currentTime = Date.now();

      return currentTime > signedDate.getTime();

    }
    return true;
  }

  registerbackgroundCheckConsent = async () => {
    const uid = this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const signedData: IServiceAgreementConsent = {
      userId: uid,
      timeStamp: Date.now(),
      deviceInfo: {
        userAgent: navigator.userAgent,
        operatingSystem: navigator.platform ?? navigator.userAgent
      }
    }
    await this.fireStore.addDocumentWithId(Collections.backgroundCheckConsent, uid, signedData);

  }

  subscribeToUserChanges = (userId: string, callback: (snapshot: DocumentSnapshot<DocumentData>) => void) => {
    const collectionPath = `${Collections.users}`;
    this.fireStore.subscribeDocument(collectionPath, userId, callback);
  };

  componentWillUnmount() {
    this.listener();
  }
}