import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../global/storeContext';
import { IdLeaseAgreement } from 'realhaus-sdk';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    createStyles,
    Grid,
    makeStyles,
    Menu,
    Paper,
    Theme,
    Typography,
} from '@material-ui/core';
import { paths } from '../../../routes';
import { Box, MenuItem, Stack } from '@mui/material';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import StringUtils from '../../../utils/string';
import { useIsMobile } from '../../../components/uiComponents/UIComponents';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            display: 'flex',
        },
        cardDetails: {
            display: 'flex',
            flexDirection: 'column',
            width: '500px',
            padding: '35px',
        },
        cardContent: {
            flex: '1 0 auto',
        },
        cardCover: {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        cardControls: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        alignCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '25px',
        },
    }),
);

const NoActiveLease: React.FC = () => {
    const classes = useStyles();

    const { uiStore } = useContext(RootStoreContext);

    const onSearchListings = () => {
        uiStore.goTo(paths.listings.search);
    };
    return (
        <>
            <Card variant='outlined' className={classes.cardRoot}>
                <div className={classes.cardDetails}>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant='body1'
                            style={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                            You don't have an active lease ... yet!
                        </Typography>
                        {/* <Typography variant='subtitle1' color='textSecondary'>
                            Mac Miller
                        </Typography> */}
                    </CardContent>
                    <div className={classes.cardControls}>
                        <Button color='primary' variant='contained' onClick={onSearchListings}>
                            Find you next home
                        </Button>
                    </div>
                </div>

                <CardMedia
                    className={classes.cardCover}
                    image='/img/main3.jpg'
                    title='Somewhere peaceful'
                    style={{ backgroundPositionY: 'bottom' }}
                />
            </Card>
        </>
    );
};

const ActiveLease: React.FC<{ leases: IdLeaseAgreement[] }> = ({ leases }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [pastDueObj, setPastDueObj] = React.useState<{ amount: number; daysLate: string }>({
        amount: 0,
        daysLate: '',
    });
    const { uiStore, leaseStore } = React.useContext(RootStoreContext);

    const isMobile = useIsMobile();

    const handleClickLeases = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const selectedLease = leases[selectedIndex];

    const getPastDueBill = () => {
        if (!!selectedLease) {
            leaseStore.getLeaseBills(selectedLease.id).then((bills) => {
                const today = Date.now();

                const pastBills = bills.filter((bill) => bill.dueDate < today);

                if (!!pastBills && pastBills.length > 0) {
                    const totalDues = pastBills.reduce((prev, currentVal) => {
                        return prev + Number(currentVal.amount);
                    }, 0);

                    const highestDueDate = pastBills.reduce(
                        (prev, currentVal) => {
                            return Number(currentVal.dueDate) > prev
                                ? Number(currentVal.dueDate)
                                : prev;
                        },
                        !!Number(pastBills[0].dueDate) ? Number(pastBills[0].dueDate) : 0,
                    );

                    //         get the days late
                    const millisecondsInADay = 1000 * 60 * 60 * 24;

                    const millisecondsDifference = today - highestDueDate;

                    const days = Math.floor(millisecondsDifference / millisecondsInADay);

                    const daysLateText =
                        days < 1
                            ? 'Minutes Late'
                            : days === 1
                            ? `${days} Day Late`
                            : `${days} Days Late`;

                    setPastDueObj({ amount: totalDues, daysLate: daysLateText });
                    return;
                }
                setPastDueObj({ amount: 0, daysLate: '-' });
            });
        }
    };

    useEffect(() => {
        getPastDueBill();
    }, [selectedIndex, leases]);

    return (
        <>
            <Paper variant='elevation' style={{ padding: '50px' }}>
                <div style={{ width: '100%', marginBottom: '40px' }}>
                    <Box
                        display='flex'
                        flexDirection={isMobile ? 'column' : 'row'}
                        alignItems='center'
                    >
                        <Box flexGrow={1}>
                            <Stack
                                direction='row'
                                spacing={2}
                                alignItems='center'
                                onClick={handleClickLeases}
                                style={{ cursor: 'pointer' }}
                                mb={isMobile ? 3 : 0}
                            >
                                <Typography variant='h6'>
                                    {selectedLease?.listingInfo.address.streetAddress}
                                </Typography>

                                <ExpandMore />
                            </Stack>
                        </Box>
                        <Box>
                            <Button
                                variant='outlined'
                                endIcon={<ChevronRight />}
                                color='primary'
                                onClick={() =>
                                    uiStore.goTo(
                                        `${paths.tenant.leases}?leaseId=${selectedLease.id}`,
                                    )
                                }
                            >
                                Go to lease
                            </Button>
                        </Box>
                    </Box>
                </div>
                <Grid container spacing={3} xs={12}>
                    <Grid item xs={12} md={3}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'
                            spacing={isMobile ? 0 : 3}
                        >
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                    <b>LEASE TERMS</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                        <b>Start</b>
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!!selectedLease?.moveinDate
                                            ? StringUtils.getDateTimeFormat(
                                                  selectedLease?.moveinDate,
                                              )
                                            : 0}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                        <b>Expires</b>
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!!selectedLease?.moveoutDate
                                            ? StringUtils.getDateTimeFormat(
                                                  selectedLease?.moveoutDate,
                                              )
                                            : 0}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'
                            spacing={isMobile ? 0 : 6}
                        >
                            <Grid item xs={6} style={{ maxWidth: '100%' }}>
                                <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                    <b>OPEN MAINT.</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='h6'>0</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'
                            spacing={isMobile ? 0 : 6}
                        >
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                    <b>RENT</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='h6'>
                                        {StringUtils.asCurrency(selectedLease?.rentAmount)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                        <b> MONTHLY</b>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Grid
                            container
                            direction='column'
                            alignItems='center'
                            spacing={isMobile ? 0 : 6}
                        >
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                    <b>PAST DUE</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='h6' color='error'>
                                        {StringUtils.asCurrency(pastDueObj.amount)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={4} style={{ maxWidth: '100%' }}>
                                <Stack alignItems='center'>
                                    <Typography variant='subtitle1' style={{ opacity: 0.6 }}>
                                        <b> {pastDueObj.daysLate}</b>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            {/* Leases Menu */}
            <Menu
                id='lease-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {leases.map((option, index) => (
                    <MenuItem
                        key={option.id}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option.listingInfo.address.streetAddress}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export const LeaseSummary: React.FC = observer(() => {
    const { leaseStore } = useContext(RootStoreContext);
    const [activeLeases, setActiveLeases] = useState<IdLeaseAgreement[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        const init = async () => {
            // fetch lease aggrement
            const leases = (await leaseStore.getTenantActiveLeaseAgreements())?.sort(
                (a, b) => a.moveoutDate - b.moveoutDate,
            );

            if (!!leases && leases?.length > 0) {
                setActiveLeases(leases);
            }
            setLoading(false);
        };

        init();
    }, []);
    return (
        <>
            {!loading && (
                <>
                    {!activeLeases || activeLeases.length < 1 ? (
                        <NoActiveLease />
                    ) : (
                        <ActiveLease leases={activeLeases} />
                    )}
                </>
            )}
        </>
    );
});
