import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Typography,
} from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { RootStoreContext } from '../../global/storeContext';
import { AdminPageLayout, useIsMobile } from '../../components/uiComponents/UIComponents';
import { PersonaModal } from '../../components/identityVerification/personaModal';
import { useStyles } from './styles';
import {
    InputFieldPhone,
    validatePhoneNumber,
} from '../../components/formComponents/inputfieldPhone';
import { IProfile } from 'realhaus-sdk';
import { InputField } from '../../components/formComponents/inputfield';
import { InputVariant } from '../../enums/InputVariant';
import { StringIsNullOrWhitespace } from '../../global/util';

const PersonalInfoComponent: React.FC = observer(() => {
    const { userStore, uiStore } = useContext(RootStoreContext);
    const { getProfile, userInfo } = userStore;
    const [profile, setProfile] = React.useState<IProfile>({} as IProfile);
    const [selectedDOB, setSelectedDOB] = React.useState<Date | null>(new Date(Date.now()));
    const [changesMade, setChangesMade] = React.useState(false);
    const [openVerificationModal, setOpenVerificationModal] = React.useState(false);
    const [userPhoto, setUserPhoto] = React.useState<string>('');
    const fileInput = React.useRef<HTMLInputElement>(null);
    const classes = useStyles();
    const [unsubscribe, setUnsubscribe] = useState<any>();

    // listening for changes to user data
    const userDocListener = () => {
        if (!!unsubscribe) return;
        if (!!userInfo) {
            const subscribedId = userStore.subscribeToUserChanges(userInfo.uid, () => {
                fetchProfile();
            });
            setUnsubscribe(subscribedId);
        }
    };

    const isMobile = useIsMobile();

    useEffect(() => {
        getProfile().then((p) => {
            const prof: IProfile =
                p ??
                ({
                    dateOfBirth: Date.now(),
                } as IProfile);
            setProfile(prof);
            const dob = p?.dateOfBirth ? new Date(p.dateOfBirth) : null;
            setSelectedDOB(dob);
        });
        const userData = userStore.userInfo;
        if (!!userData) {
            setUserPhoto(userData.photoURL);
        }

        userDocListener();
    }, []);

    const fetchProfile = () => {
        getProfile().then((p) => {
            const prof: IProfile =
                p ??
                ({
                    dateOfBirth: Date.now(),
                } as IProfile);
            setProfile(prof);

            const dob = p?.dateOfBirth ? new Date(p.dateOfBirth) : new Date(Date.now());
            setSelectedDOB(dob);
        });
    };

    const handleOpenVerificationModal = () => {
        setOpenVerificationModal(true);
    };

    const handleCloseVerificationModal = () => {
        setOpenVerificationModal(false);
    };

    const handleIDVerificationComplete = () => {
        uiStore.showLoading();
        handleCloseVerificationModal();
    };

    const handleChange = (prop: keyof IProfile) => (value: string | null) => {
        setProfile({ ...profile, [prop]: value ?? '' });

        if (prop === 'phoneNumber' && validatePhoneNumber(value as string) === true) {
            setChangesMade(false);
            return;
        }
        setChangesMade(true);
    };

    const handleDOBChange = (date: Date | null) => {
        setSelectedDOB(date);
        setChangesMade(true);

        profile.dateOfBirth = date?.getTime() ?? 0;
        setProfile(profile);
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.currentTarget.files) return;
        const file = e.currentTarget.files[0];
        const fileSize = Math.round(file.size / 1024);
        const maxFileSize = 3072;
        if (fileSize > maxFileSize) {
            // file too large
            const errorMsg = 'Invalid file. The file size must not be more than 3 mb';
            console.error(errorMsg);
            uiStore.error(errorMsg);
            return;
        }

        uiStore.showLoading();
        userStore
            .updateProfilePicture(file)
            .then((path) => {
                setUserPhoto(path ?? '');

                if (path) {
                    uiStore.success('Your profile photo has been changed');
                }
            })
            .finally(() => {
                uiStore.hideLoading();
            });
    };

    const handleSave = async () => {
        uiStore.showLoading();
        try {
            if (
                StringIsNullOrWhitespace(profile.firstname) ||
                StringIsNullOrWhitespace(profile.lastname)
            ) {
                uiStore.error('Missing required fields. First and Last name is required');
                return;
            }
            await userStore.updateProfile(profile).then(() => {
                uiStore.success('profile updated');
            });
        } catch (err) {
            console.log(err);
            uiStore.error(
                'We could not update your profile. Ensure all fields are correctly filled and try again',
            );
        } finally {
            uiStore.hideLoading();
        }
    };

    const profilePhotoComponent = () => {
        return (
            <>
                <IconButton
                    onClick={() => {
                        fileInput.current?.click();
                    }}
                >
                    <Avatar alt='profile image' src={userPhoto} className={classes.profileAvatar} />
                </IconButton>
                <Typography variant='subtitle2' style={{ textAlign: 'center' }}>
                    Click photo to update
                </Typography>
                {/* Hidden File selection input */}
                <input
                    hidden
                    ref={fileInput}
                    type='file'
                    name='dp_img_file'
                    id='dp_img_file'
                    accept='image/*'
                    onChange={handleFileUpload}
                />
            </>
        );
    };

    const profileFormComponent = () => {
        return (
            <>
                {/* First Name */}
                <Grid item xs={6}>
                    <Typography variant='subtitle2' gutterBottom>
                        First Name
                    </Typography>
                    <InputField
                        id='firstName'
                        placeholder='First Name'
                        fullWidth
                        margin='dense'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={profile.firstname}
                        variant={InputVariant.STANDARD}
                        onChange={handleChange('firstname')}
                        required
                    />
                </Grid>
                {/* Last Name */}
                <Grid item xs={6}>
                    <Typography variant='subtitle2' gutterBottom>
                        Last Name
                    </Typography>
                    <InputField
                        id='lastName'
                        placeholder='Last Name'
                        fullWidth
                        margin='dense'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant={InputVariant.STANDARD}
                        value={profile.lastname}
                        onChange={handleChange('lastname')}
                        required
                    />
                </Grid>
                {/* Date Of Birth */}
                <Grid item xs={6}>
                    <Typography variant='subtitle2' gutterBottom>
                        Date Of Birth
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent='space-around'>
                            <KeyboardDatePicker
                                variant='inline'
                                format='MMMM dd, yyyy'
                                margin='normal'
                                id='dob-picker'
                                value={selectedDOB}
                                maxDate={new Date(Date.now())}
                                onChange={handleDOBChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                fullWidth
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
                {/* Gender */}
                <Grid item xs={6}>
                    <Typography variant='subtitle2' gutterBottom>
                        Gender
                    </Typography>
                    <InputField
                        select
                        margin='normal'
                        id='gender'
                        value={profile.gender ?? ''}
                        onChange={handleChange('gender')}
                        fullWidth
                        variant={InputVariant.STANDARD}
                        placeholder={''}
                    >
                        <MenuItem value='male'>Male</MenuItem>
                        <MenuItem value='female'>Female</MenuItem>
                        <MenuItem value='other'>Other</MenuItem>
                    </InputField>
                </Grid>
                {/* phone number */}
                <Grid item xs={12}>
                    <Typography variant='subtitle2' gutterBottom>
                        Phone Number
                    </Typography>
                    <InputFieldPhone
                        id='phoneNumber'
                        fullWidth
                        value={profile.phoneNumber}
                        variant={InputVariant.STANDARD}
                        onChange={handleChange('phoneNumber')}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={handleSave}
                            disabled={!changesMade || uiStore.loading}
                        >
                            Save
                        </Button>
                    </div>
                </Grid>
            </>
        );
    };

    const profilePhotoAndVerificationComponent = () => {
        return (
            <>
                <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    justifyContent='center'
                    direction='column'
                >
                    <Grid item xs={12}>
                        {profilePhotoComponent()}
                    </Grid>
                    <Grid item xs={12}>
                        <List
                            component='nav'
                            subheader={
                                <ListSubheader component='div'>
                                    <Typography variant='subtitle1'>Verified Info</Typography>
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <VerifiedUserIcon fontSize='large' />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        (profile as any).isVerified
                                            ? 'Identity Verified'
                                            : 'Identity Unverified'
                                    }
                                    secondary={
                                        (profile as any).isVerified ? null : (
                                            <Link
                                                component='button'
                                                variant='subtitle1'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleOpenVerificationModal();
                                                }}
                                                disabled={
                                                    !profile.firstname ||
                                                    !profile.lastname ||
                                                    !profile.dateOfBirth
                                                }
                                            >
                                                Begin Verification
                                            </Link>
                                        )
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <form noValidate autoComplete='off'>
                <Grid container spacing={3} alignItems='center' justifyContent='center'>
                    {isMobile && (
                        <>
                            <Grid item md={4}>
                                {profilePhotoAndVerificationComponent()}
                            </Grid>
                        </>
                    )}
                    <Grid item md={8}>
                        <Grid container spacing={2} alignItems='center' justifyContent='center'>
                            {profileFormComponent()}
                        </Grid>
                    </Grid>
                    {!isMobile && (
                        <>
                            <Grid item md={4}>
                                {profilePhotoAndVerificationComponent()}
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>

            {/* Verification Modal- Persona */}
            <PersonaModal
                person={{
                    firstname: profile.firstname,
                    lastname: profile.lastname,
                    dob: {
                        year: new Date(profile.dateOfBirth).getFullYear(),
                        month: new Date(profile.dateOfBirth).getMonth() + 1,
                        day: new Date(profile.dateOfBirth).getDate(),
                    },
                }}
                closeVerificationModal={handleCloseVerificationModal}
                openVerificationModal={openVerificationModal}
                onVerificationComplete={handleIDVerificationComplete}
            />
        </>
    );
});

export const PersonalInfoPage: React.FC = observer(() => {
    return (
        <AdminPageLayout
            header={
                <div>
                    <Breadcrumbs separator='›' aria-label='breadcrumb'>
                        <Link color='inherit' href='/account'>
                            Account
                        </Link>
                        <Typography color='textPrimary'>Personal info</Typography>
                    </Breadcrumbs>
                    <Typography variant='h4' gutterBottom>
                        Personal info
                    </Typography>
                </div>
            }
            content={<PersonalInfoComponent />}
            maxWidth='md'
        />
    );
});
