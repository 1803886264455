import React, { useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';

import { GoogleMapsApiService } from '../../global/google-maps';
import { RootStoreContext } from '../../global/storeContext';
import { AutocompletePrediction, AutocompletionRequest } from 'realhaus-sdk';

const SearchablePlaceTypes = [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5',
    'intersection',
    'landmark',
    'locality',
    'neighborhood',
    'political',
    'post_box',
    'postal_code',
    'premise',
    'street_address',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4',
    'sublocality_level_5',
    'subpremise',
    'University',
];

interface SearchLocationProps {
    placeholder?: string;
    outlined?: boolean;
    label?: string;
    streetValue?: string;
    onLocationSelected: (value: AutocompletePrediction | null) => void;
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

const mapService: { current?: GoogleMapsApiService | null } = {};

export const SearchLocationAutocomplete: React.FC<SearchLocationProps> = observer(
    ({ placeholder, outlined, label, onLocationSelected, streetValue }) => {
        const classes = useStyles();
        const [value, setValue] = useState<AutocompletePrediction | null>(null);
        const [inputValue, setInputValue] = useState('');
        const [options, setOptions] = useState<AutocompletePrediction[]>([]);
        // const loaded = useRef(false);
        const { googleMapStore } = useContext(RootStoreContext);

        // Load Google Api Services
        // if (!loaded.current) {
        //     mapService.current = new GoogleMapsApiService();
        //     loaded.current = true;
        // }

        const fetchPlace = useMemo(
            () =>
                throttle(
                    (
                        request: AutocompletionRequest,
                        callback: (results?: AutocompletePrediction[]) => void,
                    ) => mapService.current?.getPlacePredictions(request, callback),
                    200,
                ),
            [],
        );

        useEffect(() => {
            if (!!streetValue) {
                const initialAutoprediction = {
                    description: streetValue,
                    place_id: '',
                    reference: '',
                    structured_formatting: {
                        main_text: streetValue,
                        secondary_text: '',
                        main_text_matched_substrings: [
                            {
                                offset: 0,
                                length: 0,
                            },
                        ],
                    },
                    terms: [{ offset: 0, value: '' }],
                    types: [''],
                } as AutocompletePrediction;
                setValue(initialAutoprediction);
            }
        }, [streetValue]);

        useEffect(() => {
            let active = true;
            mapService.current = googleMapStore.apiService;
            if (!mapService.current) {
                return undefined;
            }

            if (inputValue === '') {
                setOptions(value ? [value] : []);
                return undefined;
            }

            // fetch places within canada
            fetchPlace(
                { input: inputValue, componentRestrictions: { country: 'ca' } },
                (results?: AutocompletePrediction[]) => {
                    if (active) {
                        let newOptions = [] as AutocompletePrediction[];

                        if (value) {
                            newOptions = [value];
                        }

                        if (results) {
                            newOptions = [
                                ...newOptions,
                                ...results.filter((result) =>
                                    SearchablePlaceTypes.includes(result.types[0]),
                                ),
                            ];
                        }

                        setOptions(newOptions);
                    }
                },
            );

            return () => {
                active = false;
            };
        }, [value, inputValue, streetValue, fetchPlace, googleMapStore.apiService]);

        const locationChanged = (_event: any, newValue: AutocompletePrediction | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            onLocationSelected(newValue);
        };

        return (
            <Autocomplete
                autoComplete
                fullWidth
                includeInputInList
                filterSelectedOptions
                id='search-location-autocomplete'
                getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                noOptionsText='No location found'
                value={value}
                onChange={locationChanged}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder ?? 'Where will you like to live?'}
                        fullWidth
                        variant={outlined ? 'outlined' : 'standard'}
                    />
                )}
                renderOption={(option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <Grid container alignItems='center'>
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{ fontWeight: part.highlight ? 700 : 400 }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                                <Typography variant='body2' color='textSecondary'>
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        );
    },
);
