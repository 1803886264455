import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { RootStoreContext } from './global/storeContext';
import { observer } from 'mobx-react-lite';
import routes, { IRoute, paths } from './routes';
import { Box, Link, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Loader } from './components/loader/loader';
import { getURLFromParams } from './utils/query';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const AuthGate: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const { userStore, uiStore, walletStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (!userStore.isAuthenticated) {
            uiStore.goTo(paths.login, window.location.href);
        }
        walletStore.getWallet().then(async (wallet) => {
            if (!!wallet) {
                const isPositiveTransactionInLast30Days =
                    await walletStore.isPositiveTransactionInLast30Days();
                console.log(wallet.amount < 0 && !isPositiveTransactionInLast30Days);
                if (wallet.amount < 0 && !isPositiveTransactionInLast30Days) {
                    uiStore.goTo(paths.account.financials);
                }
            }
        });
    }, [userStore, userStore.userId, uiStore]);

    return <>{children}</>;
});

const NoAuthGate: React.FC<{ children?: React.ReactNode }> = observer(({ children }) => {
    const { userStore, uiStore } = useContext(RootStoreContext);
    useEffect(() => {
        if (userStore.isAuthenticated) {
            const redirectURL = getURLFromParams(window.location.href);
            if (!!redirectURL) {
                uiStore.goTo(redirectURL);
            } else {
                uiStore.goTo(paths.home);
            }
        }
    }, [userStore, userStore.userId, uiStore]);

    return <>{children}</>;
});

const App: React.FC = observer(() => {
    const { uiStore, userStore, analyticsStore } = useContext(RootStoreContext);
    const location = useLocation();
    const ldClient = useLDClient();
    const alertMsg = () => {
        const msg = uiStore.toastrMsg?.message ?? '';
        return <div dangerouslySetInnerHTML={{ __html: msg }} />;
    };

    useEffect(() => {
        // track location changes
        analyticsStore.trackPage(location.pathname);
    }, [location]);

    useEffect(() => {
        const identify = async () => {
            await ldClient?.waitForInitialization();
            const ctx = { anonymous: userStore.userId ? false : true, key: userStore.userId };
            await ldClient?.identify(ctx, undefined, () => {
                console.log('>>> LD Client Context updated');
            });
        };
        identify();
    }, [userStore.isAuthReady, userStore.userId]);

    return (
        <>
            <div className='App'>
                <Loader loading={uiStore.loading} />

                <Routes>
                    {routes.map((prop: IRoute, key) => {
                        if (prop.authGuard) {
                            return (
                                // <Route path={prop.path} key={key} element={<prop.component />}>
                                <Route
                                    path={prop.path}
                                    key={key}
                                    element={
                                        <AuthGate>
                                            <prop.component />
                                        </AuthGate>
                                    }
                                ></Route>
                            );
                        } else if (prop.noAuthGuard) {
                            return (
                                <Route
                                    path={prop.path}
                                    key={key}
                                    element={
                                        <NoAuthGate>
                                            <prop.component />
                                        </NoAuthGate>
                                    }
                                ></Route>
                            );
                        } else {
                            return (
                                <Route
                                    path={prop.path}
                                    element={<prop.component />}
                                    key={key}
                                ></Route>
                            );
                        }
                    })}
                    {/* <Navigate to='/' /> */}
                </Routes>
                <Snackbar
                    open={uiStore.toastrOpen}
                    autoHideDuration={6000}
                    onClose={uiStore.closeToastr}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant='filled'
                        onClose={uiStore.closeToastr}
                        severity={uiStore.toastrMsg?.severity ?? 'info'}
                    >
                        {alertMsg()}
                    </MuiAlert>
                </Snackbar>
            </div>
        </>
    );
});

export default App;
