import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { RootStoreContext } from '../../global/storeContext';
import { TenantStore } from '../../stores/tenantStore';
import { UserStore } from '../../stores/userStore';
import { GridContent, GridMainContent, GridSideContent } from '../uiComponents/UIComponents';
import { WizardStep } from '../../interfaces/wizard';
import { Button, Grid, SwipeableDrawer, Typography } from '@material-ui/core';
import { WizardComponent } from '../uiComponents/wizard';
import { DatePicker } from '../datePicker/datepicker';
import { BioInfoComponent } from './bioInfo';
import { AddressInfoComponent } from './addressInfo';
import { FinanceInfoComponent } from './financeInfo';
import { AdditionalInfoComponent } from './additionalInfo';
import { IsValidNumber, StringIsNullOrWhitespace } from '../../global/util';
import { isValidAddress } from '../../utils/address';
import { useStyles } from './styles';
import { format } from 'date-fns';
import {
    ITenantProfile,
    IdPropertyListing,
    IRentApplicationForm,
    IRentApplication,
    RentApplicationStatus,
    IUserBio,
    IListingTerm,
} from 'realhaus-sdk';
import { CompleteAccountSetupDialog } from './dialogs/completeAccountSetupDialog';
import { BackgroundCheckConsentDialog } from './dialogs/backgroundCheckConsentDialog';

export const fullTenantInfo = async (tenantStore: TenantStore, userStore: UserStore) => {
    const profile = await tenantStore.getTenantProfile().then((p) => {
        if (!p) {
            return {
                occupation: { incomeType: 'monthly' },
            } as ITenantProfile;
        } else {
            return p;
        }
    });
    const bio = await userStore.getProfile().then((b) => {
        if (!b) {
            const acctProfile = userStore.userProfile;
            return {
                firstname: acctProfile?.firstname,
                lastname: acctProfile?.lastname,
                gender: acctProfile?.gender,
                dateOfBirth: acctProfile?.dateOfBirth,
            } as IUserBio;
        } else {
            return b;
        }
    });

    return { profile, bio };
};

enum RentApplicationSteps {
    MOVEINFO,
    BIO,
    ADDRESS,
    FINANCE,
    ADDITIONALINFO,
}

export const RentApplicationComponent: React.FC<{
    listingId: string;
    onSubmitted: () => void;
    onCancel: () => void;
    leaseTerm: IListingTerm | undefined;
}> = observer(({ listingId, onSubmitted, onCancel, leaseTerm }) => {
    const { tenantStore, userStore, uiStore, listingStore } = useContext(RootStoreContext);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [listing, setListing] = React.useState<IdPropertyListing | undefined>();
    const [userBio, setUserBio] = useState<IUserBio>({} as IUserBio);
    const [tenantProfile, setTenantProfile] = useState<ITenantProfile>({} as ITenantProfile);
    const [rentApplication, setRentApplication] = useState({} as IRentApplicationForm);

    const handleProfileChange = (prop: keyof ITenantProfile) => (value: any) => {
        setTenantProfile({ ...tenantProfile, [prop]: value });
    };

    const handleUpdateUserBio = (prop: keyof IUserBio) => (value: any) => {
        setUserBio({ ...userBio, [prop]: value });
    };

    const handleRentApplicationChange = (prop: keyof IRentApplicationForm) => (value: any) => {
        setRentApplication({ ...rentApplication, [prop]: value });
    };

    const handleSubmitApplication = () => {
        const invalidSteps = steps.filter((x) => !x.validate());
        if (invalidSteps.length > 0) {
            const errMsg = `Steps ${invalidSteps
                .map((s) => s.label)
                .join(
                    ',',
                )} have incomplete fields. Complete all fields then try submit your application again`;
            uiStore.error(errMsg);
            return;
        }

        try {
            // all steps valid, submit application
            // save tenant profile
            tenantStore
                .updateProfile(tenantProfile)
                .then(() => {
                    tenantStore
                        .submitRentApplication(listingId, rentApplication)
                        .then((application) => {
                            onSubmitted();
                        })
                        .catch((err) => {
                            console.log('Error submitting application', err);
                        });
                })
                .catch((err) => {
                    console.log('Error updating profile', err);
                });
        } catch (err) {
            console.log('Error submitting application', err);
        }
    };

    const minDate =
        Date.now() > (leaseTerm?.dateAvailable ?? 0)
            ? new Date(Date.now())
            : new Date(leaseTerm?.dateAvailable ?? 0);

    const steps: WizardStep[] = [
        {
            label: 'Move-In',
            step: RentApplicationSteps.MOVEINFO,
            component: (
                <MoveInDateComponent
                    minDate={minDate}
                    selectedDate={rentApplication.moveinDate}
                    onMoveInChanged={handleRentApplicationChange('moveinDate')}
                />
            ),
            validate: () => !!rentApplication.moveinDate && rentApplication.moveinDate > 0,
        },
        {
            label: 'Your Info',
            step: RentApplicationSteps.BIO,
            component: (
                <BioInfoComponent
                    profile={tenantProfile}
                    userBio={userBio}
                    updateProfile={handleProfileChange}
                    updateUserBio={handleUpdateUserBio}
                />
            ),
            validate: () => {
                return (
                    !StringIsNullOrWhitespace(userBio.firstname) &&
                    !StringIsNullOrWhitespace(userBio.lastname) &&
                    !StringIsNullOrWhitespace(userBio.gender) &&
                    IsValidNumber(userBio.dateOfBirth) &&
                    !!tenantProfile.emergencyContact &&
                    !StringIsNullOrWhitespace(tenantProfile.emergencyContact.name) &&
                    !StringIsNullOrWhitespace(tenantProfile.emergencyContact.email) &&
                    !StringIsNullOrWhitespace(tenantProfile.emergencyContact.phone)
                );
            },
        },
        {
            label: 'Address',
            step: RentApplicationSteps.ADDRESS,
            component: (
                <AddressInfoComponent profile={tenantProfile} updateProfile={handleProfileChange} />
            ),
            validate: () =>
                !!tenantProfile.address &&
                isValidAddress(tenantProfile.address.address) &&
                IsValidNumber(tenantProfile.address.moveinDate) &&
                tenantProfile.address.moveinDate > 0,
        },
        {
            label: 'Income',
            step: RentApplicationSteps.FINANCE,
            component: (
                <FinanceInfoComponent profile={tenantProfile} updateProfile={handleProfileChange} />
            ),
            validate: () => !!tenantProfile.occupation && !!tenantProfile.occupation.status,
        },
        {
            label: 'Additional Info',
            step: RentApplicationSteps.ADDITIONALINFO,
            component: (
                <AdditionalInfoComponent
                    rentApplication={rentApplication}
                    updateInfo={handleRentApplicationChange}
                />
            ),
            validate: () =>
                !!rentApplication.hasPets &&
                !!rentApplication.hasVehicle &&
                !!rentApplication.smokes,
        },
    ];

    const applicationHeader = () => {
        return (
            <>
                {/* <Typography variant='h6'>Rent Application</Typography> */}
                <Typography variant='h6' display='inline'>
                    <b>Rental Application for: &nbsp;</b>
                </Typography>
                <Typography variant='h5' display='inline'>
                    {listing?.address.unit ? `${listing?.address.unit} - ` : null}
                    {`${listing?.address.streetAddress}, ${listing?.address.city}, ${listing?.address.postalCode}`}
                </Typography>
            </>
        );
    };

    useEffect(() => {
        listingStore.getListing(listingId).then((l) => {
            setListing(l);
        });
        fullTenantInfo(tenantStore, userStore).then(({ profile, bio }) => {
            setTenantProfile(profile);
            setUserBio(bio);
        });
    }, [tenantStore, userStore]);

    return (
        <>
            <GridMainContent>
                <WizardComponent
                    steps={steps}
                    title={applicationHeader()}
                    onSubmit={handleSubmitApplication}
                    onCancel={onCancel}
                    finishLabel='Submit Application'
                />
            </GridMainContent>
        </>
    );
});

const MoveInDateComponent: React.FC<{
    selectedDate: number;
    onMoveInChanged: (date: number) => void;
    minDate: Date;
}> = observer(({ selectedDate, onMoveInChanged, minDate }) => {
    const [selectedMoveinDate, setSelectedMoveinDate] = React.useState<Date>(
        selectedDate ? new Date(selectedDate) : minDate,
    );

    const handleMoveinDateChange = (date: Date | null) => {
        setSelectedMoveinDate(date ?? new Date(Date.now()));
        onMoveInChanged(date?.getTime() ?? 0);
    };

    useEffect(() => {
        onMoveInChanged(selectedMoveinDate.getTime());
    }, []);

    return (
        <>
            <GridContent>
                <GridMainContent>
                    <Typography variant='h5' gutterBottom>
                        When will you like to move in?
                    </Typography>
                </GridMainContent>
                <GridMainContent withSidebar={true}>
                    <Grid item xs={12}>
                        <Typography variant='body1' gutterBottom>
                            MOVE-IN Date
                        </Typography>
                        <div style={{ maxWidth: '300px' }}>
                            <DatePicker
                                id='movein-picker'
                                selectedDate={selectedMoveinDate}
                                onChange={handleMoveinDateChange}
                                minDate={minDate}
                            />
                        </div>
                    </Grid>
                </GridMainContent>
                <GridSideContent>
                    <Typography variant='body1' align='justify'>
                        Let the landlord know when you will like move in.
                    </Typography>
                    <br />
                    <Typography variant='body1' align='justify'>
                        This date is negotiable.
                        <br />
                        Should your application get accepted, you can adjust this date when you sign
                        your lease.
                    </Typography>
                </GridSideContent>
            </GridContent>
        </>
    );
});

export const ApplyToRentButton: React.FC<{
    listingId: string;
    onSubmitted: () => void;
    leaseTerm: IListingTerm | undefined;
}> = observer(({ listingId, onSubmitted, leaseTerm }) => {
    const classes = useStyles();
    const { tenantStore, userStore } = useContext(RootStoreContext);
    const [rentApplication, setRentApplication] = useState<IRentApplication | null>(null);
    const [showIsUserVerifiedDialog, setShowIsUserVerifiedDialog] = useState<boolean>(false);
    const [backgroundCheckConsentDialog, setBackgroundCheckConsentDialog] =
        React.useState<boolean>(false);

    useEffect(() => {
        if (!!listingId) {
            tenantStore.getRentApplication(listingId).then((app) => {
                const openApplication = app?.find(
                    (x) =>
                        x.status === RentApplicationStatus.PENDING ||
                        x.status === RentApplicationStatus.APPROVED,
                );
                if (!!openApplication) setRentApplication(openApplication);
            });
        }
    }, [listingId]);

    const [rentApplicationModalOpen, setRentApplicationModalOpen] = React.useState<boolean>(false);

    const handleRentApplicationModalOpen = async () => {
        const acctSetupSteps = await userStore.getAccountSetupSteps();
        const requiresConsent = await userStore.requiresConsent();
        if (!acctSetupSteps?.IDENTITY_VERIFIED || !acctSetupSteps.USER_PROFILE_COMPLETE) {
            setShowIsUserVerifiedDialog(true);
            return;
        }
        if (requiresConsent) {
            setBackgroundCheckConsentDialog(true);
            return;
        }
        setRentApplicationModalOpen(true);
    };
    const handleRentApplicationModalClose = () => {
        setRentApplicationModalOpen(false);
    };

    const handleRentApplicationSubmitted = () => {
        handleRentApplicationModalClose();
        onSubmitted();
    };

    const handleCloseUserVerifiedDialog = () => setShowIsUserVerifiedDialog(false);
    const handleCloseBackgroundCheckConsentDialog = (consentCaptured?: boolean) => {
        if (!!consentCaptured) {
            setBackgroundCheckConsentDialog(false);
            setRentApplicationModalOpen(true);
        } else {
            setBackgroundCheckConsentDialog(false);
        }
    };

    return (
        <>
            {!!rentApplication ? (
                <Typography gutterBottom>
                    You applied to this listing on {format(rentApplication.dateSubmitted, 'PP')}
                </Typography>
            ) : (
                <Button variant='outlined' color='primary' onClick={handleRentApplicationModalOpen}>
                    Apply Now
                </Button>
            )}

            {/* Rental Application Modal */}
            <SwipeableDrawer
                className={classes.applicationDrawer}
                anchor='right'
                open={rentApplicationModalOpen}
                onOpen={handleRentApplicationModalOpen}
                onClose={handleRentApplicationModalClose}
            >
                <RentApplicationComponent
                    listingId={listingId}
                    leaseTerm={leaseTerm}
                    onSubmitted={handleRentApplicationSubmitted}
                    onCancel={handleRentApplicationModalClose}
                />
            </SwipeableDrawer>

            <CompleteAccountSetupDialog
                openCompleteProfileDialog={showIsUserVerifiedDialog}
                handleCloseDialog={handleCloseUserVerifiedDialog}
            />
            <BackgroundCheckConsentDialog
                openBackgroundCheckConsentDialog={backgroundCheckConsentDialog}
                handleCloseBackgroundCheckConsentDialog={handleCloseBackgroundCheckConsentDialog}
            />
        </>
    );
});
