import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { BankAccountPurpose } from 'realhaus-sdk';
import { RootStoreContext } from '../../global/storeContext';
import {
    PlaidLinkOnSuccess,
    PlaidLinkOnEvent,
    PlaidLinkOnExit,
    PlaidLinkOptions,
    usePlaidLink,
} from 'react-plaid-link';
import { ConnectedAccounts, IFormattedBankConnection } from '.';

export const BankAccountComponent: React.FC = observer(() => {
    const { bankAccountStore, uiStore } = useContext(RootStoreContext);
    const [payMethods, setPayMethods] = useState<IFormattedBankConnection[]>([]);
    const [plaidCredentials, setPlaidCredentials] = useState({
        link_token: '',
        expiration: '',
    });

    const fetchAccounts = async () => {
        const accounts = await bankAccountStore.getUserBankAccounts(BankAccountPurpose.PAYMENTS);
        setPayMethods(accounts?.data ?? []);
    };

    const fetchPlaidCredentials = async () => {
        const res = await bankAccountStore.getLinkToken();
        if (res) {
            setPlaidCredentials((credentials) => ({
                ...credentials,
                ...res,
            }));
        }
    };

    const handleAccountConnected = async (
        publicToken: string,
        institution: string,
        institutionId: string,
    ) => {
        try {
            uiStore.showLoading();
            const res = await bankAccountStore.exchangePublicToken({
                publicToken,
                institution,
                institutionId,
                bankAccountPurpose: BankAccountPurpose.PAYMENTS,
            });
            if (res) {
                uiStore.success('Account Successfully Linked');
                await fetchAccounts();
            }
        } catch (err) {
            console.log(err);
        } finally {
            uiStore.hideLoading();
        }
    };

    const removeConnection = async () => {
        try {
            uiStore.showLoading();
            await bankAccountStore.removeBankAccountConnection(BankAccountPurpose.PAYMENTS);
            await fetchAccounts();
        } catch (err) {
            console.log(err);
        } finally {
            uiStore.hideLoading();
        }
    };

    useEffect(() => {
        const init = async () => {
            uiStore.showLoading();
            try {
                await fetchAccounts();
                await fetchPlaidCredentials();
            } catch (err) {
                console.log(err);
            } finally {
                uiStore.hideLoading();
            }
        };

        init();
    }, []);

    // handle link token expiration
    useEffect(() => {
        if (plaidCredentials.expiration) {
            const end = new Date(plaidCredentials.expiration);
            const start = new Date();
            const difference = end.getTime() - start.getTime();
            const timerId = setTimeout(() => {
                fetchPlaidCredentials();
            }, difference);
            clearTimeout(timerId);
        }
    }, [plaidCredentials.expiration]);

    const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
        handleAccountConnected(
            publicToken,
            metadata.institution?.name ?? '',
            metadata.institution?.institution_id ?? '',
        );
        // send public_token to your server
        // https://plaid.com/docs/api/tokens/#token-exchange-flow
    }, []);
    const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
        // log onEvent callbacks from Link
        // https://plaid.com/docs/link/web/#onevent
    }, []);
    const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
        // log onExit callbacks from Link, handle errors
        // https://plaid.com/docs/link/web/#onexit
        if (error) {
            uiStore.error(error.display_message);
        }
    }, []);

    const config: PlaidLinkOptions = {
        token: plaidCredentials.link_token,
        onSuccess,
        onEvent,
        onExit,
    };

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(config);

    return (
        <>
            <Grid
                container
                style={{ marginTop: '24px', marginBottom: '40px' }}
                justifyContent='space-between'
                spacing={2}
            >
                <Grid item>
                    <div>
                        <Typography variant='h6' gutterBottom>
                            Payments method
                        </Typography>
                        <Typography gutterBottom variant='body1' color='textSecondary'>
                            Connect a bank account. This will be used to pay bills and/or receive
                            withdrawals you request.
                        </Typography>
                    </div>
                </Grid>
                <Grid item>
                    <Typography display='inline'>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            disabled={!ready}
                            onClick={() => open()}
                        >
                            Connect Bank Account
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
            <ConnectedAccounts connections={payMethods} onRemoveConnection={removeConnection} />
        </>
    );
});
