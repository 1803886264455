import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ShowingTimeslot } from 'realhaus-sdk';
import { UpcomingShowingTimeslotCard } from '../../../../components/showings/showingTimeslotCards';
import { RootStoreContext } from '../../../../global/storeContext';
import { paths } from '../../../../routes';

export const ReservedShowings: React.FC = observer(() => {
    const [timeslots, setTimeslots] = React.useState<
        ({ propertyId: string; listingAddress: string; listingId: string } & {
            id: string;
        } & ShowingTimeslot)[]
    >([]);

    const [reservedTimeslots, setReservedTimeslots] = React.useState<
        ({ propertyId: string; listingAddress: string; listingId: string } & {
            id: string;
        } & ShowingTimeslot)[]
    >([]);

    const { listingStore, uiStore } = React.useContext(RootStoreContext);

    const fetchTimeslots = async () => {
        const listings = await listingStore.getUserListings();
        if (!listings) return;

        const slots = [];
        for (let listing of listings) {
            const pSlots = await listingStore.getPropertyShowingTimeslots(listing.propertyId);
            const newSlots = pSlots.map((slot) => ({
                propertyId: listing.propertyId,
                listingAddress: listing.address.streetAddress,
                listingId: listing.listingId,
                ...(slot as { id: string } & ShowingTimeslot),
            }));
            slots.push(...newSlots);
        }
        const sortedSlots = sortSlots(slots);

        setTimeslots(sortedSlots);
    };

    const sortSlots = (
        slots:
            | ({ propertyId: string; listingAddress: string; listingId: string } & {
                  id: string;
              } & ShowingTimeslot)[]
            | undefined,
    ) => slots?.sort((a, b) => a.timestamp - b.timestamp) ?? [];

    useEffect(() => {
        fetchTimeslots();
    }, [listingStore]);

    useEffect(() => {
        setReservedTimeslots([...timeslots.filter((slots) => !!slots.reservedBy)]);
    }, [timeslots]);

    const handleCancelTimeslot = (timeslotId: string, propertyId: string) => () => {
        if (!!timeslotId) {
            uiStore.showLoading();
            listingStore
                .cancelPropertyShowingTimeslot(propertyId, timeslotId)
                .then((ts) => {
                    if (!!ts) {
                        setTimeslots([...timeslots.filter((t) => t.id !== timeslotId), ts]);
                    }
                })
                .finally(() => {
                    uiStore.hideLoading();
                });
        }
    };

    const viewListing = (listingId: string) => {
        if (!!listingId) {
            const propertyUrl = paths.listings.detail.replace(':listingId', listingId);
            uiStore.goTo(propertyUrl);
        }
    };

    return (
        <>
            <Grid container>
                <Typography variant='h5' gutterBottom>
                    Upcoming Showings
                </Typography>

                <Grid container spacing={1}>
                    {reservedTimeslots.length === 0 ? (
                        <div>There are no scheduled timeslots!</div>
                    ) : null}
                    <Box
                        width={'calc(100vw)'}
                        overflow={'scroll'}
                        display={'-webkit-inline-box'}
                        py={1}
                    >
                        {reservedTimeslots.map((timeslot) => (
                            <Grid
                                item
                                xs={10}
                                sm={6}
                                md={4}
                                key={timeslot.id}
                                style={{ marginRight: '10px' }}
                            >
                                <UpcomingShowingTimeslotCard
                                    timeslot={timeslot}
                                    handleCancelTimeslot={handleCancelTimeslot(
                                        timeslot.id,
                                        timeslot.propertyId,
                                    )}
                                    handleViewProperty={() => {
                                        viewListing(timeslot.listingId);
                                    }}
                                    address={timeslot.listingAddress}
                                />
                            </Grid>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
});
