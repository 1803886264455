import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Avatar,
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { useStyles } from './styles';
import { format } from 'date-fns';
import { streetAddress } from '../../utils/address';
import {
    IAddress,
    RatingsForLandlord,
    RatingsForTenant,
    RentApplicationStatus,
} from 'realhaus-sdk';
import { overallAverage } from '../../utils/ratings';
import { useIsMobile } from '../uiComponents/UIComponents';

interface HeaderProps {
    name: string;
    address?: IAddress | null;
    dateSubmitted: number;
    ratings: (RatingsForLandlord | RatingsForTenant)[];
    status: RentApplicationStatus;
    onRejectApplication: (reason: string) => void;
    onAcceptApplication: () => void;
}

export const RentApplicationScreeningHeaderComponent: React.FC<HeaderProps> = observer(
    ({
        name,
        dateSubmitted,
        address,
        ratings,
        status,
        onRejectApplication,
        onAcceptApplication,
    }) => {
        const classes = useStyles();
        const isMobile = useIsMobile();

        const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
        const [rejectReason, setRejectReason] = React.useState('');

        const [openAcceptDialog, setOpenAcceptDialog] = React.useState(false);

        const openRejectConfirmation = () => {
            setOpenRejectDialog(true);
        };

        const closeRejectConfirmation = () => {
            setOpenRejectDialog(false);
        };

        const rejectApplication = () => {
            onRejectApplication(rejectReason);
            closeRejectConfirmation();
        };
        const acceptApplication = () => {
            onAcceptApplication();
            onCloseAcceptConfirmation();
        };

        const onOpenAcceptConfirmation = () => {
            setOpenAcceptDialog(true);
        };
        const onCloseAcceptConfirmation = () => {
            setOpenAcceptDialog(false);
        };

        const rating = Number(overallAverage(ratings).toFixed(2));

        const applicationStatusMessage = () => {
            switch (status) {
                case RentApplicationStatus.REJECTED: {
                    return 'This application has been rejected.';
                }
                case RentApplicationStatus.WITHDRAWN: {
                    return 'This application has been withdrawn';
                }
                case RentApplicationStatus.APPROVED: {
                    return 'This application has been approved';
                }
                default: {
                    return '';
                }
            }
        };

        return (
            <>
                {/* right alignment */}
                <Grid container justifyContent='space-between' spacing={2}>
                    <Grid container item xs={12} md={6} spacing={4}>
                        <Grid
                            item
                            xs={12}
                            md={2}
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <Avatar alt='Profile-photo' className={classes.headerAvatar}></Avatar>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={10}
                            style={{ textAlign: isMobile ? 'center' : 'left' }}
                        >
                            <Typography variant='h5'>
                                <b>{name}</b>
                            </Typography>
                            <Typography variant='subtitle1'>
                                <b>Applied on:</b>{' '}
                                <Typography variant='body1' display='inline'>
                                    {format(dateSubmitted, 'PP')}
                                </Typography>
                            </Typography>

                            <Typography>
                                <StarIcon color='secondary' style={{ verticalAlign: 'middle' }} />{' '}
                                {rating ?? 'Ratings Unavailable'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box textAlign='center'>
                            {!!address ? (
                                <>
                                    <Typography variant='h6' gutterBottom={true}>
                                        {streetAddress(address)},<br />
                                        {address.city}, {address.province}
                                    </Typography>
                                </>
                            ) : null}

                            {status === RentApplicationStatus.PENDING ? (
                                <>
                                    <div className={classes.actionButtons}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={openRejectConfirmation}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={onOpenAcceptConfirmation}
                                        >
                                            Accept
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <Typography variant='subtitle1' color='primary'>
                                    {applicationStatusMessage()}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {/* Reject Confirmation Dialog */}
                <Dialog
                    open={openRejectDialog}
                    onClose={closeRejectConfirmation}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <DialogTitle id='alert-dialog-title'>Reject Application</DialogTitle>
                    <DialogContent className={classes.modalContent}>
                        <Typography variant='subtitle1'>
                            <b>Are you sure you want to reject the application?</b>
                        </Typography>

                        <TextField
                            autoFocus
                            margin='dense'
                            id='statusReason'
                            type='text'
                            fullWidth={true}
                            placeholder='Provide a reason for cancelling the application (optional).'
                            variant='outlined'
                            multiline
                            minRows={4}
                            maxRows={4}
                            onChange={(e) => setRejectReason(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        <Button onClick={closeRejectConfirmation} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={rejectApplication} color='primary' variant='contained'>
                            Reject
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Accept Confirmation Dialog */}
                <Dialog
                    open={openAcceptDialog}
                    onClose={onCloseAcceptConfirmation}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                    fullWidth={true}
                >
                    <DialogTitle id='alert-dialog-title'>Accept Application</DialogTitle>
                    <DialogContent className={classes.modalContent}>
                        <Typography variant='body1'>
                            After accepting the application, you will be redirected to generate a
                            lease agreement.
                        </Typography>
                        <Typography variant='body1'>
                            Do you want to <b>accept and generate</b> the lease agreement now?
                        </Typography>
                    </DialogContent>

                    <DialogActions className={classes.modalFooter}>
                        <Button onClick={onCloseAcceptConfirmation} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={acceptApplication} color='primary' variant='contained'>
                            Generate Lease
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
