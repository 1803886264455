import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    MenuItem,
    InputLabel,
} from '@material-ui/core';
import { ListingPrice, useStyles } from './styles';
import { Overlay, OverlayContent, Spacing } from '../../styles';
import { paths } from '../../../routes';
import { RootStoreContext } from '../../../global/storeContext';
import {
    IdProperty,
    IdPropertyListing,
    IListingTerm,
    IListing,
    IProperty,
    ListingStatus,
} from 'realhaus-sdk';
import { PageTitle, AdminPageLayout } from '../../../components/uiComponents/UIComponents';
import { CompleteAccountSetupDialog } from '../../../components/rentApplication/dialogs/completeAccountSetupDialog';

const PropertyCard = (prop: { listingId: string; listing: IListing & IProperty }) => {
    const { listingId, listing: l } = prop;
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const classes = useStyles();
    const [term, setTerm] = useState<IListingTerm>();
    const [isListingActive, setIsListingActive] = useState<boolean>();
    const [localListing, upDateLocalListing] = useState<IListing & IProperty>(l);
    const [openConfirmListingModal, setOpenConfirmListingModal] = React.useState<boolean>(false);
    useEffect(() => {
        listingStore.getListingTerm(listingId).then((t) => setTerm(t));
        listingStore.hasActiveLeaseAgreement(listingId).then((t) => setIsListingActive(t));
    }, [listingId, listingStore]);

    const editListingPath = (listingId: string) =>
        paths.landlord.editListing.replace(':listingId', listingId).replace(':pageId', 'terms');

    const viewListingPath = (listingId: string) =>
        paths.listings.detail.replace(':listingId', listingId);

    const updateListingStatus = (listingId: string) => () => {
        uiStore.showLoading();

        if (localListing.isListed) {
            // action to unlist a listing
            listingStore
                .updateListingStatus(listingId, false)
                .then(() => {
                    upDateLocalListing((ll) => ({ ...ll, isListed: false }));
                })
                .finally(() => uiStore.hideLoading());
            return;
        } else {
            // action to list a listing
            if (!!term) {
                if (!!isListingActive && term.dateAvailable < Date.now()) {
                    uiStore.hideLoading();
                    setOpenConfirmListingModal(true);
                } else {
                    listingStore
                        .updateListingStatus(listingId, true)
                        .then(() => {
                            upDateLocalListing((ll) => ({ ...ll, isListed: true }));
                        })
                        .finally(() => uiStore.hideLoading());
                }
            }
        }
    };

    const renderStatus = () => {
        return !!localListing.isListed ? ListingStatus.LISTED : ListingStatus.UNLISTED;
    };

    const handleCloseConfirmListingModal = () => {
        setOpenConfirmListingModal(false);
    };

    return (
        <>
            <Grid item xs={12} sm={12} md={4}>
                <Card className={classes.root}>
                    <CardActionArea href={editListingPath(listingId)}>
                        {localListing.photos && localListing.photos.length > 0 ? (
                            <CardMedia
                                className={classes.cover}
                                image={localListing.photos[0].url}
                            />
                        ) : null}
                        <Overlay>
                            <OverlayContent>
                                <Typography variant='button' display='block'>
                                    {renderStatus()}
                                </Typography>
                            </OverlayContent>
                        </Overlay>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography variant='h5'>{localListing.title}</Typography>
                                <Spacing />
                                <Typography variant='h6'>
                                    {localListing.address.streetAddress}
                                    {localListing.address.unit
                                        ? `, ${localListing.address.unit}`
                                        : null}
                                </Typography>
                                <Typography variant='subtitle1' color='textSecondary'>
                                    {localListing.address.city}, {localListing.address.province}
                                </Typography>
                                <ListingPrice></ListingPrice>
                                <Typography variant='subtitle1' color='primary'>
                                    ${term?.rentAmount} / month
                                </Typography>
                            </CardContent>
                        </div>
                    </CardActionArea>
                    <CardActions>
                        <Button href={editListingPath(listingId)} color='primary'>
                            Manage
                        </Button>
                        <Button href={viewListingPath(listingId)} color='primary'>
                            View
                        </Button>
                        <Button
                            onClick={updateListingStatus(listingId)}
                            color={localListing.isListed ? 'secondary' : 'primary'}
                        >
                            {localListing.isListed ? 'Unlist' : 'List'}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <ConfirmListingModal
                openConfirmListingModal={openConfirmListingModal}
                closeConfirmListingModal={handleCloseConfirmListingModal}
                listingId={listingId}
            />
        </>
    );
};

const NewPropertyModal: React.FC<{
    existingProperties: IdProperty[];
    modalOpen: boolean;
    closeModal: () => void;
}> = observer(({ existingProperties, modalOpen, closeModal }) => {
    const { uiStore } = useContext(RootStoreContext);
    const classes = useStyles();
    const [newListingType, setNewListingType] = React.useState('new');
    const [selectedPropertyId, setSelectedPropertyId] = React.useState<String | null>(null);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (!event.target.value || event.target.value === '') return;

        const selected = event.target.value as string;
        setSelectedPropertyId(selected);
    };

    const isCloneProperty = () => newListingType === 'clone';
    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={closeModal}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <DialogContent className={classes.modalContent} dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h6'>
                                Would you like to create a listing from an existing property?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='listingType'
                                    defaultValue='new'
                                    value={newListingType}
                                    onChange={(_e, v) => {
                                        setNewListingType(v);
                                    }}
                                >
                                    <FormControlLabel
                                        value='new'
                                        control={<Radio color='primary' />}
                                        label='Create New Property'
                                    />
                                    <FormControlLabel
                                        value='clone'
                                        control={<Radio color='primary' />}
                                        label='Create from Existing Property'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <InputLabel id='property-label'>Select a property</InputLabel>
                            <TextField
                                select
                                margin='normal'
                                id='property'
                                fullWidth
                                required
                                variant='outlined'
                                value={selectedPropertyId ?? ''}
                                onChange={handleChange}
                                disabled={!isCloneProperty()}
                            >
                                {existingProperties?.map((property) => (
                                    <MenuItem key={property.id} value={property.id}>
                                        {`${property.address.streetAddress}, ${property.address.city}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        href='#'
                        color='primary'
                        variant='contained'
                        disabled={
                            isCloneProperty() && (!selectedPropertyId || selectedPropertyId === '')
                        }
                        onClick={() => {
                            if (
                                (isCloneProperty() && !selectedPropertyId) ||
                                selectedPropertyId === ''
                            ) {
                                uiStore.error('Select a property to continue');
                                return;
                            }

                            const url =
                                newListingType === 'new'
                                    ? paths.landlord.newListing
                                    : `${paths.landlord.newListing}?c=${selectedPropertyId}`;
                            console.log('navigating to = ', url);
                            uiStore.goTo(url);
                        }}
                    >
                        Create Listing
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export const ConfirmListingModal: React.FC<{
    openConfirmListingModal: boolean;
    closeConfirmListingModal: () => void;
    listingId: string;
}> = observer(({ openConfirmListingModal, closeConfirmListingModal, listingId }) => {
    const { uiStore } = React.useContext(RootStoreContext);
    return (
        <>
            <Dialog
                open={openConfirmListingModal}
                onClose={closeConfirmListingModal}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <DialogContent dividers>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                There is an active lease agreement for this listing.
                                <br />
                                To list this listing, review the terms of the listing and update the
                                date available.
                                <br />
                                <br />
                                Would you like to update the terms now?
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmListingModal} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        href='#'
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            uiStore.goTo(`${paths.landlord.listings}/${listingId}/terms`);
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export const ListingsPage: React.FC = observer(() => {
    const { listingStore, uiStore } = useContext(RootStoreContext);
    const [newPropertyModalOpen, setNewPropertyModalOpen] = React.useState<boolean>(false);
    const [listings, setListings] = useState<IdPropertyListing[]>([]);
    const [properties, setProperties] = useState<IdProperty[]>([]);
    const { userStore } = React.useContext(RootStoreContext);
    const [openCompleteProfileDialog, setOpenCompleteProfileDialog] = React.useState(false);

    useEffect(() => {
        const init = () => {
            listingStore.getUserListings().then(async (listings) => {
                setListings(listings ?? []);
            });
            listingStore.getUserProperties().then(async (properties) => {
                setProperties(properties ?? []);
            });
        };
        init();
    }, []);

    const handleCreateListing = async () => {
        const acctSetupSteps = await userStore.getAccountSetupSteps();
        if (!acctSetupSteps?.IDENTITY_VERIFIED || !acctSetupSteps.USER_PROFILE_COMPLETE) {
            setOpenCompleteProfileDialog(true);
            return;
        } else if (properties.length > 0) {
            setNewPropertyModalOpen(true);
        } else {
            uiStore.goTo(paths.landlord.newListing);
        }
    };

    const handleCloseDialog = () => {
        setOpenCompleteProfileDialog(false);
    };

    const Header = () => (
        <Grid container justifyContent='space-between'>
            <PageTitle>Properties</PageTitle>
            <Button
                variant='outlined'
                color='primary'
                href='#'
                size='small'
                onClick={() => {
                    handleCreateListing();
                }}
            >
                Add new property
            </Button>
        </Grid>
    );
    return (
        <>
            <AdminPageLayout
                header={<Header />}
                content={
                    <>
                        {listings.length === 0 ? (
                            <div>You have no listings yet.</div>
                        ) : (
                            listings.map((l) => (
                                <PropertyCard
                                    listingId={l.listingId}
                                    listing={l}
                                    key={l.listingId}
                                />
                            ))
                        )}
                        <NewPropertyModal
                            existingProperties={properties}
                            modalOpen={newPropertyModalOpen}
                            closeModal={() => {
                                setNewPropertyModalOpen(false);
                            }}
                        />

                        <CompleteAccountSetupDialog
                            openCompleteProfileDialog={openCompleteProfileDialog}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </>
                }
            />
        </>
    );
});
