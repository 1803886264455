import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RootStoreContext } from '../../../global/storeContext';
import { IDTransaction, TransactionType } from 'realhaus-sdk';
import { Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import StringUtils from '../../../utils/string';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        trxIcon: {
            display: 'block',
            [theme.breakpoints.down(938)]: {
                display: 'none',
            },
        },
    }),
);

export const RecentActivity: React.FC = observer(() => {
    const { walletStore } = React.useContext(RootStoreContext);
    const [transactions, setTransactions] = React.useState<IDTransaction[]>();

    useEffect(() => {
        const init = async () => {
            const trnx = (await walletStore.getWalletTransactions(null))?.transactions
                .sort((a, b) => b.timestamp - a.timestamp)
                .slice(0, 5);

            setTransactions(trnx);
        };
        init();
    }, []);

    const classes = useStyles();

    const isRentTransaction = (trnxNote: string): boolean => {
        const billRegex = /\bbill\b/i;
        const paymentRegex = /\bpayment\b/i;
        const rentRegex = /\brent\b/i;

        // Check if the strings appear in the sentence
        const billPresent = billRegex.test(trnxNote);
        const paymentPresent = paymentRegex.test(trnxNote);
        const rentPresent = rentRegex.test(trnxNote);

        return billPresent && paymentPresent && rentPresent;
    };

    const getCreditSummary = (tranx: IDTransaction): string => {
        const tranxNote = tranx.notes.toLocaleLowerCase();
        // fund wallet
        if (tranxNote.includes('fund wallet')) return 'You deposited funds';
        //for rent recieved
        else if (isRentTransaction(tranxNote)) return 'You recieved rent';
        //for any funds recieved
        else return 'You recieved funds';
    };

    const getDebitSummary = (tranx: IDTransaction): string => {
        const tranxNote = tranx.notes.toLocaleLowerCase();
        // service charge
        if (tranxNote.includes('service fee')) return 'You were charged a fee';
        // paid rent
        else if (isRentTransaction(tranxNote)) return 'You paid rent';
        //withdrawal
        else if (tranxNote.includes('withdraw')) return 'You withdrew funds';
        else return 'You paid a bill';
    };
    return (
        <>
            {!!transactions
                ? transactions.map((trnx, index) => (
                      <>
                          {trnx.type === TransactionType.CREDIT ? (
                              <Grid container spacing={2} key={index}>
                                  <Grid item xs={1} md={2} className={classes.trxIcon}>
                                      <ArrowForwardIcon color='primary' />
                                  </Grid>
                                  <Grid item xs={7} md={6}>
                                      <Typography variant='subtitle1'>
                                          {getCreditSummary(trnx)}
                                      </Typography>
                                      <Typography variant='body1'>
                                          {StringUtils.getDateTimeFormat(trnx.timestamp)}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={4} md={4}>
                                      <Typography variant='subtitle1' align='right'>
                                          <b>
                                              &#43;
                                              {StringUtils.asCurrency(trnx.amount)}
                                          </b>
                                      </Typography>
                                  </Grid>
                              </Grid>
                          ) : (
                              <Grid container spacing={2} key={index}>
                                  <Grid item xs={1} md={2} className={classes.trxIcon}>
                                      <ArrowBackIcon color='error' />
                                  </Grid>
                                  <Grid item xs={7} md={6}>
                                      <Typography variant='subtitle1'>
                                          {getDebitSummary(trnx)}
                                      </Typography>
                                      <Typography variant='body1'>
                                          {StringUtils.getDateTimeFormat(trnx.timestamp)}
                                      </Typography>
                                  </Grid>
                                  <Grid item xs={4} md={4}>
                                      <Typography variant='subtitle1' align='right'>
                                          <b>
                                              &#8722;
                                              {StringUtils.asCurrency(trnx.amount)}
                                          </b>
                                      </Typography>
                                  </Grid>
                              </Grid>
                          )}
                      </>
                  ))
                : null}
        </>
    );
});
