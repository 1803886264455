import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { TenantAppointments } from './appointments';
import { AdminPageLayout } from '../../../components/uiComponents/UIComponents';
import { MyRentalApplications } from './myRentalApplications';
import { LeaseSummary } from './leaseSummary';
import { AccountSetup } from '../../../layouts/auth/accountSetup';
import { ActivityCard } from '../../../components/uiComponents/activityCard';
import '../../style.css';

export const TenantDashboardPage: React.FC = observer(() => {
    return (
        <>
            <AdminPageLayout
                content={
                    <Grid container spacing={4}>
                        <Grid container item xs={12} md={8} spacing={4}>
                            <Grid item xs={12} className='dashboardCard'>
                                <AccountSetup />
                            </Grid>

                            <Grid item xs={12}>
                                <LeaseSummary />
                            </Grid>
                            <Grid item xs={12}>
                                <TenantAppointments />
                            </Grid>
                            <Grid item xs={12}>
                                <MyRentalApplications />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ActivityCard />
                        </Grid>
                    </Grid>
                }
            />
        </>
    );
});
