import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { RootStoreContext } from '../../global/storeContext';
import { ICreditReport } from 'realhaus-sdk';

import {
    CreditRating,
    CreditReportAddresses,
    CreditReportEmployees,
    CreditReportCollection,
    CreditReportPublicRecord,
    CreditAccountSummary,
    CreditAccountSummaryV2,
    CreditRatingV2,
} from './creditReport';

type CreditScreeningComponentProps = {
    tenantId: string;
};

export const CreditScreeningComponent: React.FC<CreditScreeningComponentProps> = observer(
    ({ tenantId }) => {
        const { creditReportStore } = useContext(RootStoreContext);
        const [creditReport, setCreditReport] = useState<ICreditReport>();
        useEffect(() => {
            const getData = async () => {
                const res = await creditReportStore.getCreditReport(tenantId);
                if (res) {
                    setCreditReport(res);
                }
            };
            getData();
        }, [tenantId]);

        const crVersion = creditReport?.version;
        return !!creditReport ? (
            <Grid container spacing={3}>
                {crVersion === 'v2' ? (
                    <CreditReportScreeningV2 creditReport={creditReport} />
                ) : (
                    <CreditReportScreeningV1 creditReport={creditReport} />
                )}
            </Grid>
        ) : null;
    },
);

const CreditReportScreeningV1: React.FC<{ creditReport: ICreditReport }> = observer(
    ({ creditReport }) => {
        return (
            <>
                <Grid item xs={12}>
                    <CreditRating creditReport={creditReport} />
                </Grid>
                {/* Addresses */}
                <Grid item xs={12}>
                    <CreditReportAddresses creditReport={creditReport} />
                </Grid>

                {/* Employers */}
                <Grid item xs={12}>
                    <CreditReportEmployees creditReport={creditReport} />
                </Grid>

                {/* Collection */}
                <Grid item xs={12}>
                    <CreditReportCollection creditReport={creditReport} />
                </Grid>

                {/* Public Records */}
                <Grid item xs={12}>
                    <CreditReportPublicRecord creditReport={creditReport} />
                </Grid>

                {/* Credit Account Summary */}
                <Grid item xs={12}>
                    <CreditAccountSummary creditReport={creditReport} />
                </Grid>
            </>
        );
    },
);

const CreditReportScreeningV2: React.FC<{ creditReport: ICreditReport }> = observer(
    ({ creditReport }) => {
        return (
            <>
                <Grid item xs={12}>
                    <CreditRatingV2 creditReport={creditReport} />
                </Grid>

                {/* Credit Account Summary */}
                <Grid item xs={12}>
                    <CreditAccountSummaryV2 creditReport={creditReport} />
                </Grid>
            </>
        );
    },
);
