import FirebaseStore from './firebaseStore';
import { makeAutoObservable } from 'mobx';
import { Collections } from './dbStore';
import { IDVerificationVendor, IIDVerification } from 'realhaus-sdk';
import { declineIDVerification } from '../utils/apiClient';

export class IDVerificationStore {
  constructor(private fireStore: FirebaseStore) {
    makeAutoObservable(this)
  }

  updatePersonaIDVerification = async (inquiryId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const data: IIDVerification = {
      vendor: IDVerificationVendor.Persona,
      vendorContextId: inquiryId,
      dateCreated: Date.now(),
      status: ''
    }
    await this.fireStore.updateDocument(Collections.idVerifications, uid, data);
  }

  getPersonaInquiryId = async (): Promise<string | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    try {
      const ref = (await this.fireStore.getDocument(Collections.idVerifications, uid));
      if (ref.exists()) {
        const data = ref.data() as IIDVerification;
        if (data.vendor === IDVerificationVendor.Persona && data.vendorContextId)
          return data.vendorContextId;
      }
    } catch (err) {
      console.log('>> Error getting persona Inquiry ID: ', err);
    }
  }

  declineIDVerification = async (inquiryId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) { return; }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || ''

    const req = { token, body: { inquiryId } };
    return await declineIDVerification(req);
  }
}