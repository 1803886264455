import { makeAutoObservable } from 'mobx';
import { getAnalytics, setUserId, logEvent, setUserProperties } from "firebase/analytics";

export class AnalyticsStore {
  constructor() {
    makeAutoObservable(this);
  }

  getAnalyticsId = (email: string) => {
    return btoa(email);
  }

  updateAnalyticsId = (email: string) => {
    const analyticsId = this.getAnalyticsId(email);
    setUserId(getAnalytics(), analyticsId);
  }

  updateUserProps = (gender?: string, dob?: number) => {
    setUserProperties(getAnalytics(), {
      'gender': gender,
      'dob': dob ? (new Date(dob)).toDateString() : null
    });
  }

  trackSignUp = (method?: 'email') => {
    logEvent(getAnalytics(), 'sign_up', { 'method': method });
  }

  trackLogin = () => {
    logEvent(getAnalytics(), 'login');
  }

  trackSearch = (query: string) => {
    logEvent(getAnalytics(), 'search', {
      'search_term': query
    })
  }

  trackPage = (path: string) => {
    logEvent(getAnalytics(), 'page_view', {
      'path': path
    })
  }

  trackBillPayment = (amount: number, method: string) => {
    logEvent(getAnalytics(), 'bill_payment', {
      'amount': amount,
      'method': method
    })
  }

  trackCreateListing = () => { }
  trackCreateLease = () => { }
}