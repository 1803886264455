import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { SearchLocationAutocomplete } from '../search/autocomplete';
import { RootStoreContext } from '../../global/storeContext';
import { StringIsNullOrWhitespace } from '../../global/util';
import { DatePicker } from '../datePicker/datepicker';
import { AddressCardReadonly, streetAddress } from './addressCard';
import { ToIAddress } from '../../utils/address';
import { IResidenceInfo, IAddress, AutocompletePrediction } from 'realhaus-sdk';

export type AddressViewerAddress = IResidenceInfo & {
    leaseId?: string;
};
interface AddressViewerProps {
    title: string;
    addresses: AddressViewerAddress[];
    readonly?: boolean;
    onAddressCreated: (address: IResidenceInfo & { landlordInfo?: LandlordInfo }) => void;
    onAddressDeleted: (address: IResidenceInfo) => void;
}

export const AddressesForm: React.FC<AddressViewerProps> = observer(
    ({ title, addresses, onAddressCreated, onAddressDeleted }) => {
        const [createAddressModalOpen, setCreateAddressModalOpen] = React.useState<boolean>(false);

        return (
            <>
                <Grid container spacing={2}>
                    {title ? (
                        <>
                            <Grid item xs={8}>
                                <Typography variant='h6' gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={() => {
                                        setCreateAddressModalOpen(true);
                                    }}
                                    style={{ float: 'right' }}
                                >
                                    Add Address
                                </Button>
                            </Grid>
                        </>
                    ) : null}
                    {addresses.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography></Typography>
                        </Grid>
                    ) : (
                        addresses.map((address) => (
                            <Grid
                                item
                                xs={addresses.length > 1 ? 6 : 12}
                                key={`key-${address.address.geoloc.geohash}-${address.moveinDate}`}
                            >
                                <AddressCardReadonly
                                    canDelete={!address.leaseId}
                                    residence={address}
                                    onDelete={() => {
                                        onAddressDeleted(address);
                                    }}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
                <CreateAddressModal
                    onModalOpen={createAddressModalOpen}
                    handleModalClose={(address?: IResidenceInfo) => {
                        if (!!address) {
                            onAddressCreated(address);
                        }
                        setCreateAddressModalOpen(false);
                    }}
                />
            </>
        );
    },
);

type CreateAddressModalResult = IResidenceInfo & { landlordInfo?: LandlordInfo };
export type LandlordInfo = { email: string; firstname: string };
export const CreateAddressModal: React.FC<{
    isCurrentAddress?: boolean;
    onModalOpen: boolean;
    handleModalClose: (address?: CreateAddressModalResult) => void;
}> = observer(({ isCurrentAddress, onModalOpen, handleModalClose }) => {
    const { googleMapStore } = useContext(RootStoreContext);
    const [moveinDate, setMoveinDate] = React.useState<Date | null>(new Date(Date.now()));
    const [moveoutDate, setMoveoutDate] = React.useState<Date | null>(null);
    const [isRentedProperty, setIsRentedProperty] = React.useState<boolean>(false);
    const [landlordInfo, setLandlordInfo] = React.useState<LandlordInfo>();
    const [address, setAddress] = React.useState<IAddress>({} as IAddress);

    const clearFields = () => {
        setIsRentedProperty(false);
        setLandlordInfo(undefined);
        setAddress({} as IAddress);
    };
    useEffect(() => {
        googleMapStore.apiService?.init();
    }, [googleMapStore]);
    const handleLandlordInfoChange =
        (prop: keyof LandlordInfo) => (event: React.ChangeEvent<{ value: string }>) => {
            setLandlordInfo({ ...landlordInfo, [prop]: event.target.value } as LandlordInfo);
        };

    const handleLocationChange = async (location: AutocompletePrediction | null) => {
        if (location && !!location.place_id) {
            await googleMapStore.apiService?.geocode(
                {
                    placeId: location?.place_id,
                },
                (results) => {
                    if (results && results?.length > 0) {
                        const addr = ToIAddress(results[0].address_components, results[0].geometry);
                        addr.unit = address.unit;

                        setAddress(addr);
                    }
                },
            );

            document.getElementById('address-unit')?.focus();
        }
    };

    const inputFieldsValid = () => {
        const { streetAddress, city, province, postalCode, country } = address;
        const isValidAddress =
            !!address &&
            !StringIsNullOrWhitespace(streetAddress) &&
            !StringIsNullOrWhitespace(city) &&
            !StringIsNullOrWhitespace(province) &&
            !StringIsNullOrWhitespace(postalCode) &&
            (postalCode.length === 6 || postalCode.length === 7) &&
            !StringIsNullOrWhitespace(country);

        return (
            (!isRentedProperty ||
                (isRentedProperty &&
                    !StringIsNullOrWhitespace(landlordInfo?.email) &&
                    !StringIsNullOrWhitespace(landlordInfo?.firstname))) &&
            !!moveinDate &&
            (isCurrentAddress ||
                (!isCurrentAddress &&
                    !!moveoutDate &&
                    moveinDate.getTime() < moveoutDate.getTime())) &&
            isValidAddress
        );
    };

    return (
        <>
            <Dialog
                open={onModalOpen}
                onClose={() => {
                    clearFields();
                    handleModalClose();
                }}
                maxWidth='sm'
                fullWidth={true}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <DialogTitle>Create an Address</DialogTitle>
                <DialogContent style={{ display: 'flex' }} dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputLabel>When did you move in?</InputLabel>
                            <DatePicker
                                id='movein-date-picker'
                                onChange={setMoveinDate}
                                maxDate={new Date(Date.now())}
                                selectedDate={moveinDate ?? new Date(Date.now())}
                            />
                        </Grid>
                        {!isCurrentAddress ? (
                            <Grid item xs={6}>
                                <InputLabel>When did you move out?</InputLabel>
                                <DatePicker
                                    id='moveout-date-picker'
                                    minDate={moveinDate ?? undefined}
                                    onChange={setMoveoutDate}
                                    selectedDate={moveoutDate ?? new Date(Date.now())}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <InputLabel>Was this a rented property?</InputLabel>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    row
                                    aria-label='position'
                                    name='isRentedProperty'
                                    value={`${isRentedProperty}`}
                                    onChange={(_e, v) => {
                                        setIsRentedProperty(/true/i.test(v));
                                    }}
                                >
                                    <FormControlLabel
                                        value='true'
                                        control={<Radio color='primary' />}
                                        label='YES'
                                    />
                                    <FormControlLabel
                                        value='false'
                                        control={<Radio color='primary' />}
                                        label='NO'
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {isRentedProperty ? (
                            <Grid item xs={12}>
                                {/* Enter landlordInfo  */}
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor='fullname-landlord'>
                                            Landlord's Full Name
                                        </InputLabel>
                                        <TextField
                                            id='fullname-landlord'
                                            placeholder='The landlord name'
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant='outlined'
                                            margin='normal'
                                            value={landlordInfo?.firstname ?? ''}
                                            onChange={handleLandlordInfoChange('firstname')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor='email'>Landlord's Email</InputLabel>
                                        <TextField
                                            id='email'
                                            placeholder='landlord@email.com'
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant='outlined'
                                            margin='normal'
                                            value={landlordInfo?.email ?? ''}
                                            onChange={handleLandlordInfoChange('email')}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Typography variant='body1' gutterBottom>
                                Find your address
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SearchLocationAutocomplete
                                        placeholder='Enter address of property'
                                        outlined={true}
                                        onLocationSelected={handleLocationChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor='unit'>Apt, Suite (optional)</InputLabel>
                                    <TextField
                                        id='address-unit'
                                        placeholder='e.g. 2401 or 20A'
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        // helperText='e.g. Suite #7'
                                        variant='outlined'
                                        value={address.unit ?? ''}
                                        onChange={(
                                            event: React.ChangeEvent<{ value: unknown }>,
                                        ) => {
                                            setAddress({
                                                ...address,
                                                unit: event.target.value as string,
                                            });
                                        }}
                                        required
                                    />
                                </Grid>
                                {!address ? null : (
                                    <Grid item xs={12}>
                                        <Typography variant='body1'>
                                            {streetAddress(address)}
                                            <br />
                                            {address.city}, {address.province} {address.postalCode}
                                            <br />
                                            {address.country}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            handleModalClose();
                        }}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            var residence: CreateAddressModalResult = {
                                address,
                                moveinDate: moveinDate?.getTime() ?? 0,
                                moveoutDate: moveoutDate?.getTime(),
                            };

                            if (isRentedProperty && landlordInfo) {
                                residence.landlordInfo = landlordInfo;
                            }

                            handleModalClose(residence);
                            clearFields();
                        }}
                        color='primary'
                        variant='contained'
                        disabled={!inputFieldsValid()}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

// interface AddressFormProps {
//     title: string;
//     address: IAddress;
//     setAddress: (address: IAddress) => void;
// }

// export const AddressForm: React.FC<AddressFormProps> = observer(
//     ({ title, address, setAddress }) => {
//         const handleChange =
//             (prop: keyof IAddress) => (event: React.ChangeEvent<{ value: unknown }>) => {
//                 setAddress({ ...address, [prop]: event.target.value });
//             };
//         return (
//             <>
//                 <Grid container spacing={2}>
//                     {title ? (
//                         <Grid item xs={12}>
//                             <Typography variant='h6' gutterBottom>
//                                 {title}
//                             </Typography>
//                         </Grid>
//                     ) : null}
//                     <Grid item xs={12}>
//                         <InputLabel id='country-label'>Country</InputLabel>
//                         <TextField
//                             select
//                             margin='normal'
//                             id='country'
//                             fullWidth
//                             required
//                             value={address?.country ?? ''}
//                             onChange={handleChange('country')}
//                         >
//                             <MenuItem value={'canada'}>Canada</MenuItem>
//                         </TextField>
//                     </Grid>
//                     {/* Street */}
//                     <Grid item xs={12}>
//                         <InputLabel htmlFor='street'>Street</InputLabel>
//                         <TextField
//                             id='street'
//                             margin='normal'
//                             placeholder='e.g. 11108 108th Avenue'
//                             fullWidth
//                             InputLabelProps={{
//                                 shrink: true,
//                             }}
//                             value={address?.streetAddress ?? ''}
//                             onChange={handleChange('streetAddress')}
//                             required
//                         />
//                     </Grid>
//                     {/* Unit */}
//                     <Grid item xs={12}>
//                         <InputLabel htmlFor='unit'>Apt, Suite (optional)</InputLabel>
//                         <TextField
//                             id='unit'
//                             placeholder='Apt, Suite'
//                             margin='normal'
//                             fullWidth
//                             InputLabelProps={{
//                                 shrink: true,
//                             }}
//                             helperText='e.g. Suite #7'
//                             value={address?.unit ?? ''}
//                             onChange={handleChange('unit')}
//                             required
//                         />
//                     </Grid>
//                     {/* City */}
//                     <Grid item xs={6}>
//                         <InputLabel htmlFor='city'>City</InputLabel>
//                         <TextField
//                             id='city'
//                             margin='normal'
//                             fullWidth
//                             InputLabelProps={{
//                                 shrink: true,
//                             }}
//                             value={address?.city ?? ''}
//                             onChange={handleChange('city')}
//                             required
//                         />
//                     </Grid>
//                     {/* State */}
//                     <Grid item xs={6}>
//                         <InputLabel htmlFor='province'>Province</InputLabel>
//                         <TextField
//                             select
//                             margin='normal'
//                             id='province'
//                             fullWidth
//                             required
//                             value={address?.province ?? ''}
//                             onChange={handleChange('province')}
//                         >
//                             {provinces.map((province: ProvinceInfo) => (
//                                 <MenuItem key={province.name} value={province.abbreviation}>
//                                     {province.name}
//                                 </MenuItem>
//                             ))}
//                         </TextField>
//                     </Grid>
//                     {/* Postal Code */}
//                     <Grid item xs={6}>
//                         <InputLabel htmlFor='post-code'>Postal Code</InputLabel>
//                         <TextField
//                             id='post-code'
//                             margin='normal'
//                             fullWidth
//                             InputLabelProps={{
//                                 shrink: true,
//                             }}
//                             helperText='e.g. M2J 5N7'
//                             value={address?.postalCode ?? ''}
//                             onChange={handleChange('postalCode')}
//                             required
//                         />
//                     </Grid>
//                 </Grid>
//             </>
//         );
//     },
// );

interface AddressFormBetaProps {
    title?: string;
    address: IResidenceInfo;
    setAddress: (address: IResidenceInfo) => void;
}
export const AddressFormBeta: React.FC<AddressFormBetaProps> = observer(
    ({ title, address, setAddress }) => {
        const [createAddressModalOpen, setCreateAddressModalOpen] = React.useState<boolean>(false);
        return (
            <>
                <Grid container spacing={2}>
                    {title ? (
                        <>
                            <Grid item xs={8}>
                                <Typography variant='h6' gutterBottom>
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    onClick={() => {
                                        setCreateAddressModalOpen(true);
                                    }}
                                    style={{ float: 'right' }}
                                >
                                    {!!address ? (
                                        'UPDATE ADDRESS'
                                    ) : (
                                        <>
                                            <AddCircleOutlineOutlinedIcon />
                                            {'  '}
                                            ADD ADDRESS
                                        </>
                                    )}
                                </Button>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        {!!address ? (
                            <>
                                {/* Display address readonly and display button to update address */}
                                <AddressCardReadonly residence={address} />
                            </>
                        ) : (
                            <>
                                {/* Display button to create address */}
                                <Typography>Your address is empty. Create one</Typography>
                            </>
                        )}
                    </Grid>
                </Grid>

                <CreateAddressModal
                    isCurrentAddress={true}
                    onModalOpen={createAddressModalOpen}
                    handleModalClose={(residenceInfo?: IResidenceInfo) => {
                        if (!!residenceInfo) {
                            setAddress(residenceInfo);
                        }
                        setCreateAddressModalOpen(false);
                    }}
                />
            </>
        );
    },
);
