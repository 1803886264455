
import { IdPricedListingProperty } from 'realhaus-sdk';
import { IFilterListingParams } from "../interfaces/search";

/**
 * Filters the provided list of properties based on known search filters
 * @param listings The listing to filter
 * @param filters The values of filters to use
 * @returns The filtered list of properties listing
 */
export const filterListingProperty = (listings: IdPricedListingProperty[], filters: IFilterListingParams): IdPricedListingProperty[] => {
  return listings.filter((listing) => {
    const typeFilter = !filters.type || (filters.type && (`${filters.type}`.toLowerCase() === listing.propertyType) || (`${filters.type}`.toLowerCase() === 'room' && listing.isSharedProperty));
    const priceFilter = ((filters.maxPrice && filters.maxPrice > 0 && listing.rentAmount <= filters.maxPrice) || (!filters.maxPrice || filters.maxPrice < 0))
      && ((filters.minPrice && filters.minPrice > 0 && listing.rentAmount >= filters.minPrice) || (!filters.minPrice || filters.minPrice < 0));
    const moveinFilter = (filters.moveIn && filters.moveIn > 0 && filters.moveIn >= listing.dateAvailable) || (!filters.moveIn || filters.moveIn <= 0);
    const bedroomFilter = (!filters.bedrooms || filters.bedrooms.length === 0) || (!!filters.bedrooms && filters.bedrooms.length > 0 && !!filters.bedrooms.find((rm: string) => {
      if (rm.endsWith('plus')) {
        const r = rm.replace('plus', '');
        return +listing.bedrooms >= +r;
      } else {
        return +listing.bedrooms === +rm;
      }
    }));

    const bathroomFilter = (!filters.bathrooms || filters.bathrooms.length === 0) || (!!filters.bathrooms && filters.bathrooms.length > 0 && !!filters.bathrooms.find((rm: string) => {
      if (rm.endsWith('plus')) {
        const r = rm.replace('plus', '');
        return +listing.bathrooms >= +r;
      } else {
        return +listing.bathrooms === +rm;
      }
    }));

    return typeFilter && bedroomFilter && bathroomFilter && priceFilter && moveinFilter;
  });
};