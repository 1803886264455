
import { makeAutoObservable } from 'mobx';
import { ISearchListingQuery, ISearchListingResults } from '../interfaces/search';
import { OrderByQueryOperation, Collections, WhereQueryOperation, QueryOperation, LimitQueryOperation, StartAfterQueryOperation } from './dbStore';
import FirebaseStore from './firebaseStore';
import Geohash from 'ngeohash';
import { IBookmark } from '../interfaces/bookmark';
import { IListing, IProperty, IdProperty, IdPropertyListing, IListingTerm, IdPricedListingProperty, IdLeaseTerm, IdShowingTimeslot, ShowingTimeslot, IdRentApplication, IRentApplication, RentApplicationStatus, ITenantProfile, IUserBio, ITenantInfo, IListingMailInfo, LeaseAgreementStatus, ILeaseAgreement, } from 'realhaus-sdk';
import { DocumentData, QueryDocumentSnapshot, } from 'firebase/firestore';
import { ShowingCounterProp } from '../components/manageProperty/showings';

const DEFAULT_DISTANCE_IN_MILES = 50;
const DEGREE_LAT_PER_MILES = 0.0144927536231884;
const DEGREE_LNG_PER_MILES = 0.0181818181818182;
export const DEFAULT_LISTINGS_LIMIT = 50;

export class ListingStore {
  constructor(private fireStore: FirebaseStore) {
    makeAutoObservable(this);
  }

  activeLeaseAgreementsQuery = (listingId: string) => [
    new WhereQueryOperation('listingId', '==', listingId),
    new WhereQueryOperation('moveoutDate', '>=', Date.now()),
    new WhereQueryOperation('status', '==', LeaseAgreementStatus.SIGNED)];

  createListing = async (propertyId: string, listing: IListing) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    listing.propertyId = propertyId;
    const { title, isSharedProperty, bedroomForRent, amenities, bathroomPrivacy } = listing;
    const data: IListing = {
      title, propertyId, isSharedProperty, amenities: amenities ?? [], bathroomPrivacy, bedroomForRent, isListed: true
    };
    var added = await this.fireStore.addDocument(Collections.listings, data);

    return Promise.resolve(added.id);
  }

  createProperty = async (property: IProperty, photos: File[] = []) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    property.ownerId = uid;
    property.photos = [];

    var added = await this.fireStore.addDocument(Collections.properties, property);
    const propertyId = added.id;

    if (photos && photos.length > 0) {
      await this.uploadPropertyPhotos(propertyId, photos);
    }

    return Promise.resolve(propertyId);
  }

  updateProperty = async (propertyId: string, property: Partial<IProperty>, photos: File[] = []) => {
    await this.fireStore.updateDocument(Collections.properties, propertyId, property);

    if (photos && photos.length > 0) {
      await this.uploadPropertyPhotos(propertyId, photos);
    }
  }

  updateListing = async (listingId: string, listing: Partial<IListing>) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('You do not have permission to update this listing');
    }

    const { title, propertyId, isSharedProperty, bedroomForRent, amenities, bathroomPrivacy, isListed } = listing;

    if (!title || !propertyId || !bathroomPrivacy) { throw new Error('Data to update contains invalid fields') }

    const dataToUpdate: IListing = {
      title, propertyId,
      isSharedProperty: isSharedProperty ?? false,
      amenities: amenities ?? [], bathroomPrivacy,
      bedroomForRent: bedroomForRent ?? 0,
      isListed: isListed ?? false
    };
    await this.fireStore.updateDocument(Collections.listings, listingId, dataToUpdate);
  }

  uploadPropertyPhotos = async (propertyId: string, photos: File[]) => {
    if (!photos) {
      return;
    }
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const basePath = `properties/${propertyId}/photos`;
    var promises = photos.map((file) => this.fireStore.uploadFile(file, basePath))
    var downloadPaths = await Promise.allSettled(promises);

    var uploadResults: { id: string, url: string }[] = [];
    downloadPaths.forEach(p => {
      if (p.status === 'fulfilled') {
        uploadResults.push({ id: p.value, url: p.value });
      }
    });

    const doc = (await this.fireStore.getDocument(Collections.properties, propertyId)).data() as IProperty;

    if (doc.photos) {
      uploadResults = [...doc.photos, ...uploadResults];
    }
    doc.photos = [...uploadResults];

    await this.fireStore.replaceDocument(Collections.properties, propertyId, doc);
  }

  deleteFileByUrl = async (url: string) => {
    if (!url) {
      throw Error('you have not specified the download url for the file to delete');
    }

    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('You do not have permission to delete this file');
    }

    await this.fireStore.deleteFileByDownloadUrl(url);
  }

  deletePropertyPhotos = async (propertyId: string, value: any) => {
    if (!value) {
      throw new Error('value was not provided');
    }

    if (!propertyId) {
      throw new Error('no propertyId provided');
    }

    const uid = await this.fireStore.authService.currentUser?.uid;

    if (!uid) {
      throw new Error('You do not have permission to delete this file')
    }



    await this.fireStore.deleteArrayValueFromDocument(Collections.properties, propertyId, 'photos', value)

  }

  // add bookmark
  createBookmark = async (listingId: string) => {


    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('you do not have permission to create a bookmark')
    }
    var bookmark: IBookmark = { listings: [listingId] }
    const bookmarksRef = await this.fireStore.getDocument(Collections.bookmarks, uid);

    if (bookmarksRef.exists()) {
      bookmark = bookmarksRef.data() as IBookmark;
      if (!bookmark.listings?.find(x => x === listingId)) {

        bookmark.listings = [...(bookmark.listings ?? []), listingId];
        await this.fireStore.replaceDocument(Collections.bookmarks, uid, bookmark);
      }

    } else {
      await this.fireStore.addDocumentWithId(Collections.bookmarks, uid, bookmark);
    }
  }

  // get Bookmark
  getBookmarkedListings = async (): Promise<string[]> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return [];
    const bookmarked = await this.fireStore.getDocument(Collections.bookmarks, uid)
    if (bookmarked.exists()) {
      return (bookmarked.data() as IBookmark).listings ?? [];
    }
    return [];
  }

  // removeBookmarkById
  removeBookmarkedListing = async (listingId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;

    if (!uid) {
      throw Error('You do not have permision to unbookmark')
    }

    const bookmarked = (await this.fireStore.getDocument(Collections.bookmarks, uid));
    if (bookmarked.exists()) {
      var bookmark = bookmarked.data() as IBookmark;
      bookmark.listings = bookmark.listings.filter(x => x !== listingId);
      await this.fireStore.replaceDocument(Collections.bookmarks, uid, bookmark);
    }
  }

  getUserProperties = async (): Promise<IdProperty[]> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return [];

    var query = [
      new WhereQueryOperation('ownerId', '==', uid)
    ];

    return (await this.fireStore.findDocuments(Collections.properties, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IProperty }));
  }

  getProperty = async (propertyId: string): Promise<IProperty> => {
    return (await this.fireStore.getDocument(Collections.properties, propertyId)).data() as IProperty;
  }

  getUserListings = async (): Promise<(IdPropertyListing)[] | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const properties = await this.getUserProperties()
    if (properties.length === 0) return [];

    var query = [
      new WhereQueryOperation('propertyId', 'in', properties.map(p => p.id))
    ];

    const listings = (await this.fireStore.findDocuments(Collections.listings, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IListing }));

    return listings.map(listing => {

      const { id, ...restProperty } = properties.find(p => p.id === listing.propertyId) as IdProperty;

      const result = {
        ...listing,
        ...restProperty,
        listingId: listing.id,
      } as IdPropertyListing;

      return result;
    });
  }

  getListing = async (listingId: string): Promise<IdPropertyListing | undefined> => {

    try {
      const listing = (await this.fireStore.getDocument(Collections.listings, listingId)).data() as IListing;
      const property = (await this.fireStore.getDocument(Collections.properties, listing.propertyId)).data() as IProperty;

      return {
        ...listing,
        ...property,
        listingId,
      } as IdPropertyListing;

    } catch (err) {
      console.log('>> Error getting listing: ', err);
    }
  }

  searchAvailableListings = async (searchQueryParams: ISearchListingQuery, startAfter: QueryDocumentSnapshot<DocumentData> | null): Promise<{ lastDoc: QueryDocumentSnapshot<DocumentData> } & ISearchListingResults> => {
    const queries: QueryOperation[] = [];
    const { formattedAddress, center, distance } = searchQueryParams;

    if (center) {
      const { lat, lng } = center;
      const lowerLat = lat - DEGREE_LAT_PER_MILES * (distance || DEFAULT_DISTANCE_IN_MILES);
      const lowerLng = lng - DEGREE_LNG_PER_MILES * (distance || DEFAULT_DISTANCE_IN_MILES);

      const upperLat = lat + DEGREE_LAT_PER_MILES * (distance || DEFAULT_DISTANCE_IN_MILES);
      const upperLng = lng + DEGREE_LNG_PER_MILES * (distance || DEFAULT_DISTANCE_IN_MILES);

      const upperGeohash = Geohash.encode(upperLat, upperLng);
      const lowerGeohash = Geohash.encode(lowerLat, lowerLng);

      queries.push(new WhereQueryOperation('address.geoloc.geohash', '<=', upperGeohash));
      queries.push(new WhereQueryOperation('address.geoloc.geohash', '>=', lowerGeohash));
      queries.push(new OrderByQueryOperation('address.geoloc.geohash', 'asc'));
      queries.push(new LimitQueryOperation(DEFAULT_LISTINGS_LIMIT));
      if (startAfter?.exists()) {
        queries.push(new StartAfterQueryOperation(startAfter))
      }
    }

    // This traversal ensures that we can get all listings for property with multiple listings
    const propertySnapshot = (await this.fireStore.findDocuments(Collections.properties, queries));
    const properties = propertySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as IProperty }));
    const listings = (await Promise.all(properties.map(async property => {
      let propertyListings = (await this.fireStore.findDocuments(Collections.listings, [new WhereQueryOperation('propertyId', '==', property.id), new WhereQueryOperation('isListed', '==', true)]))
        .docs.map(doc => ({ listingId: doc.id, ...doc.data() as IListing }));

      return (await Promise.all(propertyListings.map(async listing => {
        const listingTerm = (await this.fireStore.findDocuments(Collections.listingTerms, [new WhereQueryOperation('listingId', '==', listing.listingId)])).docs.map(doc => ({ ...doc.data() as IListingTerm }))[0];
        if (!!listing && !!listingTerm) {
          return { ...listing, ...property, ...listingTerm }
        }
      }))) as IdPricedListingProperty[];

    }))).flatMap(a => a);

    const filteredListings = listings.filter((l) => !!l);

    console.log('>> Search Queries and Listings', { searchParams: searchQueryParams, propertiesQueries: queries, listings });
    return ({ lastDoc: propertySnapshot.docs[propertySnapshot.docs.length - 1], location: formattedAddress, listings: filteredListings });
  }

  getListingTerm = async (listingId: string): Promise<IdLeaseTerm | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    var existingRef = await this.fireStore.findDocuments(Collections.listingTerms, [new WhereQueryOperation('listingId', '==', listingId)]);

    if (!existingRef.empty) {
      var doc = existingRef.docs[0];
      return { id: doc.id, ...(doc.data() as IListingTerm) };
    }
  }

  upsertListingTerm = async (listingId: string, term: IListingTerm) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    term.listingId = listingId;
    var existingRef = await this.fireStore.findDocuments(Collections.listingTerms, [new WhereQueryOperation('listingId', '==', listingId)]);

    var termId;
    if (!existingRef.empty) {
      termId = existingRef.docs[0].id;
      await this.fireStore.replaceDocument(Collections.listingTerms, termId, term);
    } else {
      const added = await this.fireStore.addDocument(Collections.listingTerms, term);
      termId = added.id;
    }

    return Promise.resolve(termId);
  }

  addShowingTimeslot = async (propertyId: string, showings: ShowingCounterProp[]): Promise<IdShowingTimeslot[]> => {
    const collectionId = `${Collections.properties}/${propertyId}/${Collections.showings}`;

    const timeslots: IdShowingTimeslot[] = []

    for (const showing of showings) {
      const timestamp = showing.selectedTimeslotDatetime?.getTime() ?? Date.now();
      const duration = showing.timeslot.duration;
      var existingRef = await this.fireStore.findDocuments(collectionId, [new WhereQueryOperation('timestamp', '==', timestamp)]);

      if (!existingRef.empty) {
        const doc = existingRef.docs[0];
        const docData = doc.data() as ShowingTimeslot;
        docData.duration = duration;
        await this.fireStore.replaceDocument(collectionId, doc.id, docData);
        // await showingRef.doc(doc.id).set(docData);
        timeslots.push({ id: doc.id, ...docData });
      } else {
        const data = { timestamp, duration } as ShowingTimeslot;
        var added = await this.fireStore.addDocument(collectionId, data);
        const docData = (await this.fireStore.getDocument(collectionId, added.id)).data() as ShowingTimeslot;
        timeslots.push({ id: added.id, ...docData })
      }
    }
    return timeslots;
  }

  getPropertyShowingTimeslots = async (propertyId: string): Promise<({ id: string } & ShowingTimeslot)[]> => {
    const collectionPath = `${Collections.properties}/${propertyId}/${Collections.showings}`;
    const tsNow = Date.now();
    var existingRef = await this.fireStore.findDocuments(collectionPath,
      [
        new WhereQueryOperation('timestamp', '>', tsNow),
        new OrderByQueryOperation('timestamp', 'asc')
      ]
    );

    return existingRef.docs.map(doc => ({ id: doc.id, ...doc.data() as ShowingTimeslot }));
  }

  deletePropertyShowingTimeslot = async (propertyId: string, timeslotId: string): Promise<any> => {
    try {
      const collectionPath = `${Collections.properties}/${propertyId}/${Collections.showings}`;
      // const ref = await this.fireStore.dbService.collection('listings').doc(propertyId).collection('showings');
      // (await ref.doc(timeslotId).delete());
      await this.fireStore.deleteDocument(collectionPath, timeslotId);
      return timeslotId;
    } catch (err) {
      console.log('Could not delete Property Showing timeslot: ', timeslotId);
      return null;
    }
  }

  cancelPropertyShowingTimeslot = async (propertyId: string, timeslotId: string): Promise<any> => {
    try {
      const collectionPath = `${Collections.properties}/${propertyId}/${Collections.showings}`;

      const data = (await this.fireStore.getDocument(collectionPath, timeslotId)).data() as ShowingTimeslot;
      const dataToUpdate = { timestamp: data.timestamp, duration: data.duration } as ShowingTimeslot;
      await this.fireStore.replaceDocument(collectionPath, timeslotId, dataToUpdate);

      return { id: timeslotId, ...dataToUpdate };
    } catch (err) {
      console.log('Could not cancel property showing timeslot: ', timeslotId);
      return null;
    }
  }

  getRentApplication = async (appId: string): Promise<IdRentApplication | undefined> => {
    const ref = await (await this.fireStore.getDocument(Collections.rentApplications, appId));
    if (!ref.exists()) return;

    return { id: ref.id, ...ref.data() as IRentApplication };
  }

  getApplications = async (listingId: string): Promise<IdRentApplication[]> => {
    if (!listingId) return [];
    var query = [
      new WhereQueryOperation('listingId', '==', listingId),
    ];

    return (await this.fireStore.findDocuments(Collections.rentApplications, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IRentApplication } as IdRentApplication));
  }

  getApplicationsForListings = async (listingIds: string[]): Promise<IdRentApplication[]> => {
    if (!listingIds || listingIds.length === 0) return [];
    var query = [
      new WhereQueryOperation('listingId', 'in', listingIds),
      new WhereQueryOperation('status', '==', RentApplicationStatus.PENDING)
    ];

    return (await this.fireStore.findDocuments(Collections.rentApplications, query)).docs.map(doc => ({ id: doc.id, ...doc.data() as IRentApplication } as IdRentApplication));
  }

  getTenantProfileForApplication = async (tenantId: string) => {
    try {
      const profile = (await this.fireStore.getDocument(Collections.tenantProfiles, tenantId)).data() as ITenantProfile;
      return profile;
    } catch (err) {
      console.log('>> Error getting tenant profile: ', err);
    }
  }

  getTenantInfoForApplication = async (tenantId: string) => {
    try {
      const profile = (await this.fireStore.getDocument(Collections.tenantProfiles, tenantId)).data() as ITenantProfile;
      const userBio = (await this.fireStore.getDocument(Collections.users, tenantId)).data() as IUserBio;
      return { ...userBio, ...profile } as ITenantInfo;
    } catch (err) {
      console.log('>> Error getting tenant profile bio info: ', err);
    }
  }

  getListingIdForApplication = async (appId: string): Promise<string | undefined> => {
    if (!appId) return;
    const rentApplication = await this.getRentApplication(appId);
    return rentApplication?.listingId;
  }

  rejectApplication = async (appId: string, rejectReason: string): Promise<string | null> => {
    try {
      const updateData = { status: RentApplicationStatus.REJECTED, statusReason: rejectReason, statusDate: Date.now() } as IRentApplication;
      await this.fireStore.updateDocument(Collections.rentApplications, appId, updateData);

      return appId;

    } catch (err) {
      console.log('could not reject application: ', appId);
      return null;
    }
  }

  acceptApplication = async (appId: string): Promise<string | null> => {
    try {
      const updateData = { status: RentApplicationStatus.APPROVED, statusDate: Date.now() } as IRentApplication;
      await this.fireStore.updateDocument(Collections.rentApplications, appId, updateData);
      return appId;

    } catch (err) {
      console.log('could not accept application: ', appId);
      return null;
    }
  }

  getListingMailInfo = async (listingId: string): Promise<IListingMailInfo | undefined> => {
    if (!listingId) return
    const listing = (await this.fireStore.getDocument(Collections.listings, listingId)).data() as IListing
    const propertyId = listing.propertyId
    const property = (await this.fireStore.getDocument(Collections.properties, propertyId)).data() as IProperty
    const ownerId = property.ownerId
    const user = (await this.fireStore.getDocument(Collections.users, ownerId)).data() as IUserBio
    // get listingTerm for rent amount
    var listingTermRef = await this.fireStore.findDocuments(Collections.listingTerms, [new WhereQueryOperation('listingId', '==', listingId)]);
    var doc = listingTermRef.docs[0];
    const listingTerm = doc.data() as IListingTerm;

    // listing mail information
    const listingMailInfo = {
      listingId: listingId,
      landlordName: user.firstname,
      rentAmount: listingTerm.rentAmount,
      city: property.address.city,
      province: property.address.province
    } as IListingMailInfo

    return listingMailInfo;
  }

  hasActiveLeaseAgreement = async (listingId: string): Promise<boolean> => {
    if (!listingId) return false;
    const leaseAgreements = (await this.fireStore.findDocuments(Collections.leaseAgreements, this.activeLeaseAgreementsQuery(listingId)))
      .docs.map((doc) => ({ ...doc.data() as ILeaseAgreement }));
    return leaseAgreements.some((l) => l.moveoutDate >= new Date().getTime())
  }


  updateListingStatus = async (listingId: string, isListed: boolean) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('You do not have permission to update this listing');
    }
    await this.fireStore.updateDocument(Collections.listings, listingId, { isListed });
  }

}