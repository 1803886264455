import React, { useEffect } from 'react';
import { PaymentMethod } from './billPaymentCheckout';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../global/storeContext';
import { FinixApplicationId, FinixEnvironment, FinixMerchantId } from '../../appConfig';
import { Dialog, DialogContent } from '@material-ui/core';

export const FinixPaymentForm: React.FC<{
    openFinixTokenModal: boolean;
    onClose: () => void;
    leaseId: string;
    billId: string;
    feeBreakdown?: { [key in PaymentMethod]: { fee: number; netAmount: number } };
    selectedPaymentMethod: PaymentMethod | undefined;
    handleCloseCheckOut: () => void;
}> = observer(
    ({
        openFinixTokenModal,
        onClose,
        feeBreakdown,
        leaseId,
        billId,
        selectedPaymentMethod,
        handleCloseCheckOut,
    }) => {
        const formRef = React.useRef<HTMLDivElement>(null);
        const { uiStore, walletStore } = React.useContext(RootStoreContext);

        useEffect(() => {
            if (!selectedPaymentMethod || !feeBreakdown) {
                return;
            }
            uiStore.showLoading();
            const { fee, netAmount } = feeBreakdown[selectedPaymentMethod];

            // create transactionLog for card payment
            const initiatePayment = (tokenId: string, sessionId: string) =>
                walletStore.initiateBillPayment(
                    netAmount,
                    fee,
                    leaseId,
                    billId,
                    `${selectedPaymentMethod}`,
                    { finix: { token: tokenId, sessionId: sessionId } },
                );

            const interval = setInterval(() => {
                if ((window as any).Finix && formRef.current) {
                    clearInterval(interval); //stop interval once finix is loaded
                    uiStore.hideLoading();
                    const onSubmit = () => {
                        // call the form submit function and supply the environment and application ID to submit to
                        form.submit(
                            FinixEnvironment,
                            FinixApplicationId,
                            function (err: any, res: any) {
                                if (!!err) {
                                    console.error(err);
                                    uiStore.error('Card payment failed');
                                    return;
                                }
                                // get token ID from response
                                const tokenData = res.data || {};
                                const token = tokenData.id;

                                //   get fraud sessionId
                                (window as any).Finix.Auth(
                                    FinixEnvironment,
                                    FinixMerchantId,
                                    async (sessionKey: any) => {
                                        uiStore.showLoading();
                                        try {
                                            // pass tokenId and fraud sessionId to initiate payment
                                            const res = await initiatePayment(token, sessionKey);
                                            if (!!res?.trxRefId) {
                                                uiStore.success('Payment successful');
                                            } else {
                                                const msg =
                                                    'We were unable to pay your bill through credit card at this time. Please try again later';
                                                const err = res?.errorMsg
                                                    ? `${res.errorMsg}\n${msg}`
                                                    : msg;
                                                uiStore.error(err);
                                                console.error(err);
                                            }
                                            onClose();
                                        } catch (err) {
                                            uiStore.error('Payment request failed');
                                            console.error(err);
                                        } finally {
                                            uiStore.hideLoading();
                                        }
                                    },
                                );
                            },
                        );
                    };
                    const options = {
                        showAddress: false,

                        showLabels: true,
                        // set custom labels for each field
                        labels: {
                            name: 'Cardholder Name',
                        },
                        // turn on or off placeholder text in the fields (default is true)
                        showPlaceholders: true,
                        // set custom placeholders for each field, you can specify them here
                        placeholders: {
                            name: 'Cardholder Name',
                        },
                        // require any specific fields that are not required by default, you can specify them here
                        requiredFields: ['name'],
                        // if you want to require a field, but not hide input error messages (default is false)
                        hideErrorMessages: false,
                        // set custom error messages for each field if you are showing error messages
                        errorMessages: {
                            name: 'Please enter a valid name',
                            address_city: 'Please enter a valid city',
                        },
                        // custom styles for the form inputs (optional but recommended)
                        styles: {
                            // default styling for all fields
                            default: {
                                color: '#000',
                                border: '1px solid #CCCDCF',
                                borderRadius: '8px',
                                padding: '8px 16px',
                                fontFamily: 'Helvetica',
                                fontSize: '16px',
                                boxShadow:
                                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.03)',
                            },
                            // specific styling if the field is valid
                            success: {
                                color: '#5cb85c',
                            },
                            // specific styling if the field has errors
                            error: {
                                border: '1px solid rgba(255,0,0, 0.3)',
                            },
                        },
                        onLoad: function () {},
                        onSubmit,
                        submitLabel: 'Submit',
                    };
                    const form = (window as any).Finix.CardTokenForm('form-element', options);
                }
            }, 100);
            return () => clearInterval(interval);
        }, [openFinixTokenModal]);

        return (
            <>
                <Dialog open={openFinixTokenModal} onClose={onClose} maxWidth='sm' fullWidth>
                    <DialogContent>
                        <div
                            style={{
                                minHeight: '250px',
                                paddingTop: '10px',
                                padding: '10px',
                            }}
                        >
                            <div id='form-element' ref={formRef}>
                                {' '}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    },
);
