import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';
import { DueRentCardComponent } from './actionCardsComponents/dueRentCardComponent';
import { ExpiringLeasesCardComponent } from './actionCardsComponents/expiringLeasesCardComponent';
import { ProspectiveTenantsCardComponent } from './actionCardsComponents/prospectiveTenantsCardComponent';
import { ReservedShowings } from './actionCardsComponents/reservedShowings';
import { AccountSetup } from '../../../layouts/auth/accountSetup';
import { ActivityCard } from '../../../components/uiComponents/activityCard';
import '../../style.css';

export const ActionCards: React.FC = observer(() => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid container spacing={2} item md={8} sm={12}>
                    <Grid item xs={12} className='dashboardCard'>
                        <AccountSetup />
                    </Grid>
                    <Grid container spacing={1} item>
                        {[
                            <DueRentCardComponent />,
                            <ExpiringLeasesCardComponent />,
                            <ProspectiveTenantsCardComponent />,
                        ].map((i) => (
                            <Grid item xs={12} sm={4} md={4}>
                                {i}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <ReservedShowings />
                    </Grid>
                </Grid>
                <Grid container item md={4} sm={12}>
                    <Grid item xs={12}>
                        <ActivityCard />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});
