import { makeAutoObservable } from 'mobx';
import FirebaseStore from './firebaseStore';
import { Pets, IAddress, ITenantProfile, FeePaymentFrequency, IdLeaseAgreement, ILeaseAgreement, ILeaseClause, ILeaseFees, ILeaseInvite, ILeaseListingInfo, ILeaseSignature, LeaseAgreementStatus, LeaseEndAction, UtilityCoverage, IOccupant, ITenantInfo, IUserBio, IdBill, IBill, BillStatus, ITenantLeaseInvite, LeaseAgreementSource, ILeaseAgreementDocument, LeaseRequestDocStatus, IRequestDocumentType, AddedLeaseAgreementDocument, RentApplicationStatus } from 'realhaus-sdk';
import { Collections, OrderByQueryOperation, WhereQueryOperation } from './dbStore';
import { AddressMatch } from '../utils/address';
import { ListingStore } from './listingStore';
import { uuidv4 } from '@firebase/util';
import { add } from 'date-fns';
import * as apiClient from '../utils/apiClient';
import { IRequestDoc } from '../components/leaseAgreement/details/overview';

export class LeaseStore {
  constructor(private fireStore: FirebaseStore, private listingStore: ListingStore) {
    makeAutoObservable(this);
  }

  tenantInvitePath = (leaseAgreementId: string) => `${Collections.leaseAgreements}/${leaseAgreementId}/${Collections.leaseAgreementTenantInvites}`;
  documentsPath = (leaseAgreementId: string) => `${Collections.leaseAgreements}/${leaseAgreementId}/${Collections.leaseAgreementDocuments}`;
  requestDocumentsPath = (leaseAgreementId: string) => `${Collections.leaseAgreements}/${leaseAgreementId}/${Collections.leaseAgreementRequestDocuments}`;

  getOwnerLeaseAgreements = async (): Promise<(IdLeaseAgreement)[] | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    var entry = (await this.fireStore.findDocuments(Collections.leaseAgreements, [new WhereQueryOperation('ownerId', '==', uid)])).docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseAgreement }));
    return entry;
  }

  getLeaseAgreementsForTenant = async (): Promise<(IdLeaseAgreement)[] | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    return this.getLeaseAgreementByTenantId(uid);
  }


  getLeaseAgreementsForListing = async (listingId: string): Promise<(IdLeaseAgreement[])> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || !listingId) return [];

    return (await this.fireStore.findDocuments(Collections.leaseAgreements, [new WhereQueryOperation('listingId', '==', listingId)])).docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseAgreement }));
  }

  getLeaseAgreementByTenantId = async (tenantId: string): Promise<(IdLeaseAgreement)[] | undefined> => {
    const uid = tenantId
    if (!uid) return;
    try {
      var entry = (await this.fireStore
        .findDocuments(Collections.leaseAgreements, [new WhereQueryOperation('tenantIds', 'array-contains', uid)]))
        .docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseAgreement }));
      return entry;
    } catch (err) {
      console.error(`Error getting getLeaseAgreementByTenantId for user ${uid}`, err)
    }
  }

  getTenantActiveLeaseAgreements = async (): Promise<(IdLeaseAgreement)[] | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    const query = [new WhereQueryOperation('tenantIds', 'array-contains', uid), new WhereQueryOperation('status', '==', LeaseAgreementStatus.SIGNED)]
    try {
      var entry = (await this.fireStore
        .findDocuments(Collections.leaseAgreements, query))
        .docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseAgreement }));
      return entry;
    } catch (err) {
      console.error(`Error getting getTenantActiveLeaseAgreements for user ${uid}`, err)
    }
  }

  addNewLeaseAgreementByTenant = async (
    landlordInfo: { email: string; firstname: string; },
    leaseInfo: { address: IAddress; leaseStartDate: number; leaseEndDate: number; }): Promise<string | undefined> => {

    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    // ensure a lease agreement matching the leaseInfo  does not exist for this tenant
    const { address, leaseStartDate, leaseEndDate } = leaseInfo;

    var existingLease =
      (await this.getLeaseAgreementsForTenant() ?? []).filter(lease => {

        const sameAddress = AddressMatch(lease.listingInfo.address, address);
        const similarStartDates = ((new Date(lease.moveinDate)).getFullYear() === (new Date(leaseStartDate)).getFullYear()) &&
          ((new Date(lease.moveinDate)).getMonth() === (new Date(leaseStartDate)).getMonth());
        const similarEndDates = ((new Date(lease.moveoutDate)).getFullYear() === (new Date(leaseEndDate)).getFullYear()) &&
          ((new Date(lease.moveoutDate)).getMonth() === (new Date(leaseEndDate)).getMonth());

        return sameAddress && (similarStartDates || similarEndDates);
      });

    if (existingLease.length > 0) { return existingLease[0].id; }

    const lease: Partial<ILeaseAgreement> = {
      tenantIds: [uid],
      listingInfo: { address: leaseInfo.address } as ILeaseListingInfo,
      moveinDate: leaseInfo.leaseStartDate,
      moveoutDate: leaseInfo.leaseEndDate
    };

    // create partial lease agreement
    var created = await this.fireStore.addDocument(Collections.leaseAgreements, lease);

    // invite landlord to create account and complete lease agreement
    await this.inviteUnregisteredUserToLease(created.id, landlordInfo, true);

    return created.id;
  }

  inviteUnregisteredUserToLease = async (
    leaseId: string,
    userInfo: { email: string; firstname: string; },
    isLandlord: boolean): Promise<void> => {

    // check invitation exists
    var existingInvite = (await this.fireStore
      .findDocuments(
        Collections.leaseInvites,
        [new WhereQueryOperation('leaseId', '==', leaseId), new WhereQueryOperation('email', '==', userInfo.email)]))
      .docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseInvite }));

    if (existingInvite.length > 0) { return; }

    const invite: ILeaseInvite = {
      leaseId,
      isLandlord,
      email: userInfo.email,
      firstname: userInfo.firstname,
    };

    await this.fireStore.addDocument(Collections.leaseInvites, invite);
    //ToDo: write FirebaseFunction to send email when a new invite is created
  }

  getProvincialClauses = async (province: string): Promise<ILeaseClause[]> => {
    return [];
  }

  createDraftLeaseAgreementForNonRealhausLease = async (listingId: string): Promise<string | undefined> => {
    if (!listingId) {
      return;
    }

    const listing = await this.listingStore.getListing(listingId);
    if (!listing) {
      return;
    }
    const lease: ILeaseAgreement = {
      listingId,
      listingInfo: {
        address: listing.address,
        bathroomPrivacy: listing.bathroomPrivacy,
        bathrooms: listing.bathrooms,
        bedroomForRent: listing.bedroomForRent,
        bedrooms: listing.bedrooms,
        description: listing.description,
        isSharedProperty: listing.isSharedProperty,
        photos: listing.photos,
        propertyType: listing.propertyType,
        size: listing.size,
        title: listing.title,
        yearBuilt: listing.yearBuilt
      } as ILeaseListingInfo,
      ownerId: listing.ownerId,
      leaseEndAction: LeaseEndAction.RENEW_MONTHLY,
      status: LeaseAgreementStatus.DRAFT,
      source: LeaseAgreementSource.NON_REALHAUS,
      moveinDate: 0,
      moveoutDate: 0,
      rentApplicationId: '',
      rentAmount: 0,
      securityDepositAmount: 0,
      fees: {
        lateRentFee: 0,
        moveInFee: 0,
        moveOutFee: 0,
        petFeeFrequency: FeePaymentFrequency.NONE,
        petFee: 0,
        smokingFeeFrequency: FeePaymentFrequency.NONE,
        smokingFee: 0
      } as ILeaseFees,
      policies: {
        petsAllowed: false,
        smokingAllowed: false,
        rentInsuranceProof: false,
        autoPaymentSetup: false,
        utilitiesCovered: [],
      },
      tenantIds: [],
      signatures: [],
      rules: [],
      occupants: [], // NOTE: other occupants other than those in the tenantIds list
      clauses: [],
      changeRequests: [],
      timestamp: Date.now(),
      dueRentDay: -1
    };

    var created = await this.fireStore.addDocument(Collections.leaseAgreements, lease);
    return created.id;
  }

  createLeaseAgreementForRentApplication = async (applicationId: string): Promise<string | undefined> => {
    const rentApp = await this.listingStore.getRentApplication(applicationId);
    if (!rentApp) return;

    const listing = await this.listingStore.getListing(rentApp.listingId);
    if (!listing) return;
    const listingTerms = await this.listingStore.getListingTerm(rentApp.listingId);
    if (!listingTerms) return;
    const hasActiveLease = await this.listingStore.hasActiveLeaseAgreement(rentApp.listingId);
    if (hasActiveLease) return;

    // listingTerms.durationType ===
    const moveOutDate = add(new Date(rentApp.application.moveinDate), { months: listingTerms.duration });
    // retrieve predefined clauses for province
    const clauses = await this.getProvincialClauses(listing.address.province);
    const lease: ILeaseAgreement = {
      rentApplicationId: rentApp.id,
      listingId: rentApp.listingId,
      moveinDate: rentApp.application.moveinDate,
      moveoutDate: moveOutDate.getTime(), // add moveout date to application
      ownerId: listing.ownerId,
      tenantIds: [rentApp.tenantId],
      status: LeaseAgreementStatus.DRAFT,
      leaseEndAction: LeaseEndAction.RENEW_MONTHLY,
      rentAmount: listingTerms.rentAmount,
      securityDepositAmount: listingTerms.depositAmount,
      fees: {
        lateRentFee: 0,
        moveInFee: 0,
        moveOutFee: 0,
        petFeeFrequency: FeePaymentFrequency.NONE,
        petFee: 0,
        smokingFeeFrequency: FeePaymentFrequency.NONE,
        smokingFee: 0
      } as ILeaseFees,
      policies: {
        autoPaymentSetup: true,
        petsAllowed: listingTerms.features.houseRules.some(x => !!Pets.find(pet => pet.toUpperCase() === x.text.toUpperCase()) && x.value),
        rentInsuranceProof: true,
        smokingAllowed: !!listingTerms.features.houseRules.find(r => r.text.toUpperCase() === "SMOKING" && r.value),
        utilitiesCovered: listingTerms.features.utilities.filter(u => u.value).map(u => ({ percentage: 100, utility: u.text } as UtilityCoverage))
      },
      listingInfo: {
        address: listing.address,
        bathroomPrivacy: listing.bathroomPrivacy,
        bathrooms: listing.bathrooms,
        bedroomForRent: listing.bedroomForRent,
        bedrooms: listing.bedrooms,
        description: listing.description,
        isSharedProperty: listing.isSharedProperty,
        photos: listing.photos,
        propertyType: listing.propertyType,
        size: listing.size,
        title: listing.title,
        yearBuilt: listing.yearBuilt
      } as ILeaseListingInfo,
      signatures: [],
      rules: [],
      occupants: [], // NOTE: other occupants other than those in the tenantIds list
      clauses,
      changeRequests: [],
      timestamp: Date.now(),
      source: LeaseAgreementSource.REALHAUS,
      dueRentDay: -1
    };

    var created = await this.fireStore.addDocument(Collections.leaseAgreements, lease);
    await this.fireStore.updateDocument(Collections.listings, listing.listingId, { isListed: false });
    return created.id;
  }

  renewLeaseAgreement = async (leaseId: string) => {
    if (!leaseId) return

    const leaseAgreementSnapshot = await this.fireStore.getDocument(Collections.leaseAgreements, leaseId)

    if (!leaseAgreementSnapshot.exists()) return

    const leaseAgreement = { id: leaseAgreementSnapshot.id, ...leaseAgreementSnapshot.data() as ILeaseAgreement } as IdLeaseAgreement

    const nextMoveInDate = new Date(leaseAgreement.moveoutDate);
    nextMoveInDate.setDate(nextMoveInDate.getDate() + 1);

    const newMoveInDate = leaseAgreement.moveoutDate >= Date.now() ? nextMoveInDate.getTime() : Date.now();
    const durationInDays = Math.floor(
      (new Date(leaseAgreement.moveoutDate).getTime() - new Date(leaseAgreement.moveinDate).getTime()) / (1000 * 60 * 60 * 24)
    );

    // Add the duration (in days) to the new move-in date
    const newMoveOutDate = new Date(newMoveInDate);
    newMoveOutDate.setDate(newMoveOutDate.getDate() + durationInDays);

    const newLeaseAgreement = {
      listingId: leaseAgreement.listingId,
      moveinDate: newMoveInDate,
      moveoutDate: newMoveOutDate.getTime(), // add new moveout date
      ownerId: leaseAgreement.ownerId,
      tenantIds: leaseAgreement.tenantIds,
      status: LeaseAgreementStatus.DRAFT,
      leaseEndAction: leaseAgreement.leaseEndAction,
      rentAmount: leaseAgreement.rentAmount,
      securityDepositAmount: leaseAgreement.securityDepositAmount,
      initialLeaseAgreementId: leaseAgreement.id,
      fees: leaseAgreement.fees,
      policies: {
        autoPaymentSetup: false,
        petsAllowed: leaseAgreement.policies.petsAllowed,
        rentInsuranceProof: true,
        smokingAllowed: leaseAgreement.policies.smokingAllowed,
        utilitiesCovered: leaseAgreement.policies.utilitiesCovered
      },
      listingInfo: leaseAgreement.listingInfo,
      signatures: [],
      rules: leaseAgreement.rules,
      occupants: leaseAgreement.occupants, // NOTE: other occupants other than those in the tenantIds list
      clauses: leaseAgreement.clauses,
      changeRequests: [],
      timestamp: Date.now(),
      source: LeaseAgreementSource.REALHAUS,
      dueRentDay: leaseAgreement.dueRentDay
    }


    const created = await this.fireStore.addDocument(Collections.leaseAgreements, newLeaseAgreement)
    return { ...newLeaseAgreement, id: created.id }
  }

  getLeaseAgreementForRentApplication = async (appId: string): Promise<IdLeaseAgreement | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    var entry = (await this.fireStore.findDocuments(Collections.leaseAgreements, [new WhereQueryOperation('rentApplicationId', '==', appId)])).docs.map(doc => ({ id: doc.id, ...doc.data() as ILeaseAgreement } as IdLeaseAgreement));

    if (entry.length > 0) return entry[0];
  }

  getLeaseAgreementById = async (leaseId: string): Promise<IdLeaseAgreement | undefined> => {
    if (!leaseId) return;

    const ref = await this.fireStore.getDocument(Collections.leaseAgreements, leaseId);
    if (!ref.exists()) return;

    return { id: ref.id, ...ref.data() } as IdLeaseAgreement;
  }

  updateLeaseAgreement = async (id: string, lease: ILeaseAgreement): Promise<void> => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;

    if (lease.ownerId !== uid) return;

    if (LeaseAgreementStatus.PENDING_TENANT_SIGNATURE === lease.status) {
      // NOTE: the landlord has sent the lease for signing, we should resolve the
      // change requests on the lease if any
      const changeRequests = lease.changeRequests || [];
      lease.changeRequests = changeRequests.map((change) => {
        return {
          ...change,
          resolvedAt: change.resolvedAt || Date.now()
        };
      })
    }

    const existingLease = await this.getLeaseAgreementById(id);
    if (existingLease?.status === LeaseAgreementStatus.SIGNED) throw Error('Cannot update a signed lease');
    // TODO: find a reason to use updateDocument here instead of replaceDocument (we could have multiple viewers of the same lease)
    await this.fireStore.replaceDocument(Collections.leaseAgreements, id, lease);
  }

  endLeaseAgreement = async (lease: IdLeaseAgreement): Promise<void> => {
    const uid = await this.fireStore.authService.currentUser?.uid;

    if (!uid) return;

    if (lease.ownerId !== uid) return;
    const terminateData = { status: LeaseAgreementStatus.TERMINATED };

    const cancelData = { status: LeaseAgreementStatus.CANCELLED };

    const existingLease = await this.getLeaseAgreementById(lease.id);

    if (existingLease) {

      if (existingLease.status === LeaseAgreementStatus.DRAFT) {
        await this.fireStore.updateDocument(Collections.leaseAgreements, lease.id, cancelData);
        const initialLeaseAgreement = lease.initialLeaseAgreementId;
        if (!initialLeaseAgreement && !!lease.rentApplicationId) {
          await this.fireStore.updateDocument(Collections.rentApplications, lease.rentApplicationId, { status: RentApplicationStatus.REJECTED, statusReason: 'cancel draft lease agreement', statusDate: Date.now() })
        }
      }

      if (existingLease.status === LeaseAgreementStatus.SIGNED) {
        await this.fireStore.updateDocument(Collections.leaseAgreements, lease.id, terminateData);
        await this.fireStore.updateDocument(Collections.rentApplications, lease.rentApplicationId, { status: RentApplicationStatus.REJECTED, statusReason: 'terminate signed lease agreement', statusDate: Date.now() })
      }

    }

  }

  signLeaseAgreement = async (id: string, lease: ILeaseAgreement) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || !lease.tenantIds.some((id) => uid === id)) return;

    const signatures = lease.signatures || [];
    signatures.push({
      acknowledgedBy: uid,
      userAgent: navigator.userAgent,
      timestamp: Date.now()
    } as ILeaseSignature);

    const singedLeaseAgreement = { ...lease, status: LeaseAgreementStatus.SIGNED, signatures };
    await this.fireStore.replaceDocument(Collections.leaseAgreements, id, singedLeaseAgreement);
    return await this.getLeaseAgreementById(id);
  }

  requestChange = async (id: string, lease: ILeaseAgreement, message: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || !lease.tenantIds.some((id) => uid === id)) throw Error('Unable to make change request');

    const changeRequests = lease.changeRequests || []

    changeRequests.push({
      message,
      requestedBy: uid,
      userAgent: navigator.userAgent,
      timestamp: Date.now()
    });

    await this.fireStore.updateDocument(Collections.leaseAgreements, id, { status: LeaseAgreementStatus.DRAFT, changeRequests });
    return await this.getLeaseAgreementById(id);
  }

  updateOccupants = async (lease: IdLeaseAgreement, newOccupants: IOccupant[]) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || (!lease.tenantIds.some((id) => uid === id) && uid !== lease.ownerId)) throw Error('Unable to add occupants');
    await this.fireStore.updateDocument(Collections.leaseAgreements, lease.id, { occupants: newOccupants });
    return await this.getLeaseAgreementById(lease.id);
  }

  getTenantInfo = async (tenantId: string) => {
    try {
      const profile = (await this.fireStore.getDocument(Collections.tenantProfiles, tenantId)).data() as ITenantProfile;
      const userBio = (await this.fireStore.getDocument(Collections.users, tenantId)).data() as IUserBio;
      return { ...profile, ...userBio } as ITenantInfo;
    } catch (err) {
      console.log('>> Error getting tenant profile: ', err);
    }
  }

  leaseBillPath = (leaseId: string) => `${Collections.leaseAgreements}/${leaseId}/bills`

  getAllLeaseBills = async (leaseId: string) => {
    const ref = await this.fireStore.getDocuments(this.leaseBillPath(leaseId));
    return ref.docs.map(doc => ({ id: doc.id, ...(doc.data() as IBill) } as IdBill));
  }

  getLeaseBills = async (leaseId: string) => {
    const orderBy = new OrderByQueryOperation('dueDate', 'asc');
    const query = [orderBy];
    const ref = await this.fireStore.findDocuments(this.leaseBillPath(leaseId), query);
    return ref.docs.map(doc => ({ id: doc.id, ...(doc.data() as IBill) } as IdBill));
  }

  getLeaseBill = async (leaseId: string, billId: string) => {
    const ref = await this.fireStore.getDocument(this.leaseBillPath(leaseId), billId);
    if (!ref.exists()) return;
    return ref.data() as IBill;
  }

  updateLeaseBillStatus = async (leaseId: string, billId: string, status: BillStatus) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    const lease = await this.getLeaseAgreementById(leaseId);
    if (!uid || !lease || (!lease.tenantIds.some((id) => uid === id) && uid !== lease.ownerId)) throw Error('Unable to update lease bill status');
    await this.fireStore.updateDocument(this.leaseBillPath(leaseId), billId, { status });
    return this.getLeaseBill(leaseId, billId);
  }

  createTenantLeaseInvite = async (payload: ITenantLeaseInvite, leaseAgreementId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('you do not have permission to create a tenant lease invite')
    }
    await this.fireStore.addDocument(this.tenantInvitePath(leaseAgreementId), payload)
  }

  getTenantInvitationInfo = async (leaseAgreementId: string): Promise<({ id: string } & ITenantLeaseInvite) | undefined> => {
    try {
      const uid = await this.fireStore.authService.currentUser?.uid;
      if (!uid) {
        throw Error('You do not have permission to create a lease agreement')
      }
      let tenant = await this.fireStore.getDocuments(this.tenantInvitePath(leaseAgreementId));
      if (!tenant.empty) {
        const doc = tenant.docs[0];
        return { id: doc.id, ...doc.data() } as ({ id: string } & ITenantLeaseInvite)
      }
    } catch (error) {
      console.log(error)
    }
  }

  getLeaseAgreementDocuments = async (leaseAgreementId: string) => {
    try {
      const uid = await this.fireStore.authService.currentUser?.uid;
      if (!uid) {
        throw Error('You do not have permission to get lease agreement documents')
      }
      let documents = await this.fireStore.getDocuments(this.documentsPath(leaseAgreementId));
      if (!documents.empty) {
        return documents.docs.map(doc => ({ id: doc.id, ...doc.data() } as { id: string } & ILeaseAgreementDocument))
      }

    } catch (error) {
      console.log(error)
    }
  }

  uploadLeaseAgreementDocument = async (leaseId: string, title: string, file: File, additionalDocument?: boolean) => {
    if (!leaseId || !title || !file) {
      return;
    }
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      return;
    }

    const basePath = `lease/${leaseId}/documents`;
    const ext = file.name.substring(file.name.lastIndexOf('.'));
    const fileName = `${uuidv4()}${ext}`;
    try {

      const downloadPath = await this.fireStore.uploadFile(file, basePath, fileName);

      let doc: ILeaseAgreementDocument;
      if (additionalDocument) {
        doc = {
          title, url: downloadPath, tag: AddedLeaseAgreementDocument.ADDENDUM, ownerId: uid
        }
      } else {
        doc = { title, url: downloadPath, ownerId: uid }
      }
      const d = await this.fireStore.addDocument(this.documentsPath(leaseId), doc);

      return { id: d.id, ...doc };
    } catch (err) {
      throw new Error(err as string)
    }

  }

  deleteLeaseAgreementDocument = async (leaseId: string, docId: string, doc: ILeaseAgreementDocument) => {
    if (!doc || !doc.url) return;

    await this.fireStore.deleteFileByDownloadUrl(doc.url);
    await this.fireStore.deleteDocument(this.documentsPath(leaseId), docId);
  }

  deleteRequestDocument = async (leaseId: string, request: { id: string } & IRequestDocumentType) => {
    if (!leaseId || !request) return;
    await this.fireStore.deleteDocument(this.requestDocumentsPath(leaseId), request.id);
  }

  uploadRequestDocs = async (reqDocs: ({ id: string; file: File } & IRequestDocumentType)[], leaseId: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid || !leaseId || reqDocs.length < 0 || reqDocs.length === 0) return;

    const fulfilledRequestDocsIds: { id: string }[] = [];

    // loop through the reqDocs
    for (let reqDoc of reqDocs) {
      const basePath = `leaseAgreements/${leaseId}/documents`;
      const ext = reqDoc.file.name.substring(reqDoc.file.name.lastIndexOf('.'));
      const fileName = `${uuidv4()}${ext}`;
      try {

        const downloadPath = await this.fireStore.uploadFile(reqDoc.file, basePath, fileName);

        const doc: ILeaseAgreementDocument = {
          title: reqDoc.title, url: downloadPath, tag: AddedLeaseAgreementDocument.ADDENDUM, ownerId: uid
        }
        await this.fireStore.addDocumentWithId(this.documentsPath(leaseId), reqDoc.id, doc);
        // update status of request document
        await this.fireStore.updateDocument(this.requestDocumentsPath(leaseId), reqDoc.id, { status: LeaseRequestDocStatus.FULFILLED })
        fulfilledRequestDocsIds.push({ id: reqDoc.id });
      } catch (err) {
        throw new Error(err as string)
      }
    }
    return fulfilledRequestDocsIds;

  }

  addRequestDocuments = async (leaseId: string, requests: IRequestDoc[]) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) return;
    const requestIds = []
    if (!!uid && requests.length > 0) {
      for (const request of requests) {
        const data = {
          title: request.title,
          description: request.description,
          timestamp: Date.now(),
          status: LeaseRequestDocStatus.PENDING
        } as IRequestDocumentType
        const requestDoc = await this.fireStore.addDocument(this.requestDocumentsPath(leaseId), data);
        requestIds.push({ id: requestDoc.id })
      }
    }
    return requestIds;
  }

  getPendingRequestDocuments = async (leaseId: string): Promise<({ id: string } & IRequestDocumentType)[] | undefined> => {
    const uid = await this.fireStore.authService.currentUser?.uid
    if (!uid) return;
    const requestDoc = (await this.fireStore.getDocuments(this.requestDocumentsPath(leaseId)))
    if (!requestDoc.empty) {
      const pendingRequests = requestDoc.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as { id: string } & IRequestDocumentType).filter((rDoc) => rDoc.status === LeaseRequestDocStatus.PENDING)
      return pendingRequests;
    }
  }

  addTenantFromLeaseInviteToLease = async (
    leaseId: string,
  ) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      throw Error('User is not logged-in!');
    }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    const leaseRequest = { token, body: { leaseId } };
    return await apiClient.addTenantFromInviteToLease(leaseRequest);
  }

  sendMoney = async (receiverId: string, amount: number, notes: string) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) {
      return;
    }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || '';
    return await apiClient.sendMoney({ token, body: { amount, receiverId, notes } })
  }

  requestPayment = async (amount: number, leaseId: string, debtorId: string, reason: string, dueDate: number, attachment?: File[]) => {
    const uid = await this.fireStore.authService.currentUser?.uid;
    if (!uid) { return; }
    const token = await this.fireStore.authService.currentUser?.getIdToken() || ''
    const path = `${Collections.leaseAgreements}/${leaseId}/bills`;
    const attachments: string[] = [];
    if (attachment) {
      const promises = attachment.map((file) => this.fireStore.uploadFile(file, path));
      const downloadPaths = await Promise.allSettled(promises);

      downloadPaths.forEach((p) => {
        if (p.status === 'fulfilled') {
          attachments.push(p.value);
        }
      });
    }

    return await apiClient.requestPayment({ token, body: { amount, leaseId, debtorId, dueDate, reason, attachments } });

  }
}