import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { MainLayout } from '../../layouts/main/main';
import { SearchListingContainer, PageContent } from '../styles';
import { useStyles } from '../styles';
import { Grid, Button, Typography, Popover, Tab } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    ISearchListingQuery,
    IFilterListingParams,
    ISearchListingResults,
} from '../../interfaces/search';

import { RootStoreContext } from '../../global/storeContext';
import { initializeFilterParams, initializeSearchQuery } from '../../utils/query';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { GoogleMapsApiService } from '../../global/google-maps';
import { throttle } from 'lodash';
import { Loader } from '../../components/loader/loader';
import { filterListingProperty } from '../../utils/filter';
import { ListingMap } from './ListingMap';
import { BedsBathsFilter, HomeTypeFilter, PriceFilter } from './Filters';
import { CardListing } from '../../components/uiComponents/card';
import { ToIAddress } from '../../utils/address';
import {
    LatLngLiteral,
    IdPricedListingProperty,
    GeocoderRequest,
    GeocoderResult,
} from 'realhaus-sdk';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { DEFAULT_LISTINGS_LIMIT } from '../../stores/listingStore';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useIsMobile } from '../../components/uiComponents/UIComponents';

const useQuery = () => {
    const { googleMapStore } = useContext(RootStoreContext);
    const location = useLocation();
    const search = new URLSearchParams(location.search);

    const geolocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(success, Error);
        }

        function success(pos: GeolocationPosition) {
            const crd = pos.coords;
            const location: LatLngLiteral = {
                lat: crd.latitude,
                lng: crd.longitude,
            };
            if (location) {
                geoCode(location);
            }
        }

        function Error(err: any) {
            if (err.PERMISSION_DENIED) {
                console.log('Permission not granted');
            }
        }
    };

    const geoCode = (curLocation: LatLngLiteral) => {
        googleMapStore.apiService?.geocode({ location: curLocation }, (results, status) => {
            const checkResult = !!results && results?.length > 0;

            if (checkResult && status === 'OK') {
                const addr = ToIAddress(results[0].address_components, results[0].geometry);
                if (addr.city && addr.province && addr?.country.toLocaleLowerCase() === 'canada') {
                    search.set('q', `${addr?.city}--${addr?.province}`);
                    window.location.href = `${location.pathname}?${search.toString()}`;
                }
            }
        });
    };

    if (!search.has('q') || !search.get('q')) {
        geolocation();
        search.set('q', 'calgary--ab');
        if (window.history.pushState) {
            const url = `${location.pathname}?${search.toString()}`;
            window.history.pushState({ path: url }, '', url);
        }
    }

    return search;
};

interface FilterButtonElements {
    more: HTMLButtonElement | null;
    type: HTMLButtonElement | null;
    price: HTMLButtonElement | null;
    rooms: HTMLButtonElement | null;
    moveIn: HTMLButtonElement | null;
}
enum Tabs {
    List = 'List',
    Map = 'Map',
}

const mapService: { current?: GoogleMapsApiService | null } = {};

export const PropertiesListPage: React.FC = observer(() => {
    const classes = useStyles();

    const navigate = useNavigate();

    const loaded = useRef(false);

    const { listingStore, uiStore, analyticsStore } = useContext(RootStoreContext);

    const [loading, setLoading] = useState(true);

    const [searchParams] = useState<URLSearchParams>(useQuery());

    const [searchResults, setSearchResults] = React.useState<ISearchListingResults>({});

    const [filteredSearchResults, setFilteredSearchResults] = React.useState<
        IdPricedListingProperty[]
    >([]);

    const [searchListingQuery, setSearchListingQuery] = React.useState<ISearchListingQuery>(
        initializeSearchQuery(searchParams),
    );

    const [filterListingParams, setFilterListingParams] = React.useState<IFilterListingParams>(
        initializeFilterParams(searchParams),
    );

    const [lastQueryDoc, setLastQueryDoc] =
        React.useState<QueryDocumentSnapshot<DocumentData> | null>(null);

    const [canFetchMoreListings, setCanFetchMoreListings] = React.useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<FilterButtonElements>({
        more: null,
        type: null,
        price: null,
        rooms: null,
        moveIn: null,
    });

    const handleFilterClick = (name: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl({
            ...anchorEl,
            [name]: event.currentTarget,
        });
    };

    const handleFilterClose = (name: string) => () => {
        setAnchorEl({
            ...anchorEl,
            [name]: null,
        });
    };

    const openFilters = {
        more: Boolean(anchorEl.type),
        type: Boolean(anchorEl.type),
        price: Boolean(anchorEl.price),
        rooms: Boolean(anchorEl.rooms),
        moveIn: Boolean(anchorEl.moveIn),
    };

    const filtersIds = {
        more: openFilters.more ? 'simple-popover-for-more' : undefined,
        type: openFilters.type ? 'simple-popover-for-type' : undefined,
        price: openFilters.price ? 'simple-popover-for-price' : undefined,
        rooms: openFilters.rooms ? 'simple-popover-for-rooms' : undefined,
        moveIn: openFilters.moveIn ? 'simple-popover-for-moveIn' : undefined,
    };

    const handleFilterRoomsChange = (bedrooms: string[], bathrooms: string[]) => {
        setLoading(false);
        if (!!bedrooms && bedrooms.length > 0 && !bedrooms.includes('any')) {
            searchParams.set('bedrooms', `${bedrooms.join(',')}`);
        } else {
            bedrooms = [];
            searchParams.delete('bedrooms');
        }

        if (!!bathrooms && bathrooms.length > 0 && !bathrooms.includes('any')) {
            searchParams.set('bathrooms', `${bathrooms.join(',')}`);
        } else {
            bathrooms = [];
            searchParams.delete('bathrooms');
        }

        navigate(`/search?${searchParams.toString()}`);
        setFilterListingParams({ bedrooms, bathrooms });
    };

    const handleFilterChange = (name: string) => (value: any) => {
        setLoading(false);
        if (!!value && value !== 'any') {
            searchParams.set(name, `${value}`);
        } else {
            value = undefined;
            searchParams.delete(name);
        }
        navigate(`/search?${searchParams.toString()}`);
        setFilterListingParams({ ...filterListingParams, [name]: value });
    };

    const handleFilterDateChange = (date: MaterialUiPickersDate) => {
        setLoading(false);
        if (date) {
            searchParams.set('moveIn', `${date?.getTime()}`);
        } else {
            searchParams.delete('moveIn');
        }
        navigate(`/search?${searchParams.toString()}`);
        setFilterListingParams({ ...filterListingParams, moveIn: date?.getTime() });
    };

    const handleFilterPriceChange = (min: any, max: any) => {
        setLoading(false);
        if (!!min && min !== '-1') {
            searchParams.set('minPrice', `${min}`);
        } else {
            min = undefined;
            searchParams.delete('minPrice');
        }

        if (!!max && max !== '-1') {
            searchParams.set('maxPrice', `${max}`);
        } else {
            max = undefined;
            searchParams.delete('maxPrice');
        }

        navigate(`/search?${searchParams.toString()}`);
        setFilterListingParams({ ...filterListingParams, minPrice: min });
        setFilterListingParams({ ...filterListingParams, maxPrice: max });
    };

    if (!loaded.current) {
        // Load Google Maps Api Services (we already have the Google API script loaded on page by google-map-react)
        mapService.current = new GoogleMapsApiService(true);
    }

    const fetchAddressGeocode = useMemo(
        () =>
            throttle(
                (
                    request: GeocoderRequest,
                    callback?: (results?: GeocoderResult[], status?: any) => void,
                ) => mapService.current?.geocode(request, callback),
                200,
            ),
        [],
    );

    const findProperties = () => {
        uiStore.showLoading();
        listingStore.searchAvailableListings(searchListingQuery, lastQueryDoc).then(
            (
                results: {
                    lastDoc: QueryDocumentSnapshot<DocumentData>;
                } & ISearchListingResults,
            ) => {
                if (results.listings && results.listings.length > 0) {
                    results.listings.length < DEFAULT_LISTINGS_LIMIT //if listings on initial load is less than default_listings_limit, it lets us know there isn't another fetch to be done.
                        ? setCanFetchMoreListings(false)
                        : setCanFetchMoreListings(true);
                    setSearchResults({
                        location: results.location,
                        listings: [...(searchResults.listings ?? []), ...(results.listings ?? [])],
                    });

                    setLastQueryDoc(results.lastDoc);

                    uiStore.hideLoading();
                } else {
                    setCanFetchMoreListings(false);
                }
                uiStore.hideLoading();
            },
        );
    };

    useEffect(() => {
        setLoading(true);
        if (!searchListingQuery.center) {
            const { query } = searchListingQuery;
            fetchAddressGeocode(
                {
                    address: query,
                },
                (results?: GeocoderResult[]) => {
                    if (results && results.length > 0) {
                        const geolocation = results[0].geometry.location;
                        const center = {
                            lat: geolocation.lat(),
                            lng: geolocation.lng(),
                        } as LatLngLiteral;
                        const formattedAddress = results[0].formatted_address;
                        setSearchListingQuery({ query, center, formattedAddress });
                    }
                },
            );
        } else if (!searchResults.listings) {
            // Find properties with search query
            findProperties();
        }
        if (searchResults.listings) {
            // Filtering properties with search filter params
            setFilteredSearchResults(
                filterListingProperty(searchResults.listings, filterListingParams),
            );

            setLoading(false);
        }
        setLoading(false);
    }, [searchListingQuery, searchResults, filterListingParams, fetchAddressGeocode, listingStore]);

    useEffect(() => {
        const { query } = searchListingQuery;
        analyticsStore.trackSearch(query);
    }, [searchListingQuery]);

    const homeTypeFilterTitle = () =>
        !!searchParams.get('type') ? `*${searchParams.get('type')}` : 'Home Type';

    const priceRangeFilterTitle = () =>
        !!searchParams.get('minPrice') && !!searchParams.get('maxPrice')
            ? `$${searchParams.get('minPrice')} CAD - $${searchParams.get('maxPrice')} CAD`
            : !!searchParams.get('minPrice')
            ? `>= $${searchParams.get('minPrice')} CAD`
            : !!searchParams.get('maxPrice')
            ? `<= ${searchParams.get('maxPrice')}`
            : 'Price Range';

    const [tab, setTab] = React.useState(Tabs.List);

    const handleTabChange = (event: React.MouseEvent<HTMLElement>, t: Tabs) => {
        setTab(t);
    };
    const isMobile = useIsMobile();

    const Map = () => (
        <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
                <SearchListingContainer>
                    <ListingMap
                        listings={filteredSearchResults}
                        centerCoordinate={
                            searchListingQuery.center || {
                                lat: 0,
                                lng: 0,
                            }
                        }
                    />
                </SearchListingContainer>
            </Grid>
        </Grid>
    );
    const PropertiesList = () => (
        <Grid
            container
            item
            style={{
                maxHeight: 'calc(100vh - 270px)',
                overflowY: 'scroll',
            }}
        >
            {filteredSearchResults.length === 0 ? (
                <div>
                    <Typography display='block' variant='h6' className={classes.noWrap}>
                        No Rentals where found.
                    </Typography>
                </div>
            ) : null}
            <Grid container item spacing={1} justifyContent='flex-start' alignItems='flex-start'>
                {filteredSearchResults.map((l) => (
                    <Grid item xs={6} key={`listing-card-${l.listingId}`}>
                        <CardListing
                            listingId={l.listingId}
                            key={`listing-card-${l.listingId}`}
                            isBookmarked={() => {}}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid
                container
                item
                spacing={1}
                justifyContent='center'
                alignItems='center'
                style={{ marginTop: '1rem' }}
            >
                <Button
                    color='primary'
                    onClick={() => findProperties()}
                    disabled={!canFetchMoreListings}
                >
                    Load more...
                </Button>
            </Grid>
        </Grid>
    );
    return (
        <>
            <Loader loading={loading} />
            <MainLayout fullWidth={true}>
                <PageContent>
                    <section>
                        <Grid container spacing={2}>
                            {/* LIST */}
                            <Grid item xs={12} sm={6}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography display='block' variant='h5'>
                                            {searchListingQuery.formattedAddress
                                                ? `Rentals in ${searchListingQuery.formattedAddress}`
                                                : 'Searching...'}
                                        </Typography>
                                    </Grid>
                                    <Grid container item spacing={1}>
                                        <Grid item>
                                            <Button
                                                aria-describedby={filtersIds.type}
                                                className={classes.noWrap}
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                onClick={handleFilterClick('type')}
                                                endIcon={<ExpandMoreIcon />}
                                            >
                                                {homeTypeFilterTitle()}
                                            </Button>
                                            <Popover
                                                id={filtersIds.type}
                                                open={openFilters.type}
                                                anchorEl={anchorEl.type}
                                                onClose={handleFilterClose('type')}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <HomeTypeFilter
                                                    value={searchParams.get('type') || 'any'}
                                                    onChange={(v) => {
                                                        handleFilterChange('type')(v);
                                                        handleFilterClose('type')();
                                                    }}
                                                />
                                            </Popover>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                aria-describedby={filtersIds.price}
                                                className={classes.noWrap}
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                onClick={handleFilterClick('price')}
                                                endIcon={<ExpandMoreIcon />}
                                            >
                                                {priceRangeFilterTitle()}
                                            </Button>
                                            <Popover
                                                id={filtersIds.price}
                                                open={openFilters.price}
                                                anchorEl={anchorEl.price}
                                                onClose={handleFilterClose('price')}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <PriceFilter
                                                    minValue={searchParams.get('minPrice')}
                                                    maxValue={searchParams.get('maxPrice')}
                                                    onChange={(min, max) => {
                                                        handleFilterPriceChange(min, max);
                                                        handleFilterClose('price')();
                                                    }}
                                                />
                                            </Popover>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                aria-describedby={filtersIds.rooms}
                                                className={classes.noWrap}
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                onClick={handleFilterClick('rooms')}
                                                endIcon={<ExpandMoreIcon />}
                                            >
                                                Rooms
                                            </Button>
                                            <Popover
                                                id={filtersIds.rooms}
                                                open={openFilters.rooms}
                                                anchorEl={anchorEl.rooms}
                                                onClose={handleFilterClose('rooms')}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <BedsBathsFilter
                                                    bedrooms={searchParams.get('bedrooms') ?? 'any'}
                                                    bathrooms={
                                                        searchParams.get('bathrooms') ?? 'any'
                                                    }
                                                    onChange={(beds, baths) => {
                                                        handleFilterRoomsChange(beds, baths);
                                                        handleFilterClose('rooms')();
                                                    }}
                                                />
                                            </Popover>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                aria-describedby={filtersIds.moveIn}
                                                className={classes.noWrap}
                                                size='small'
                                                variant='outlined'
                                                color='primary'
                                                onClick={handleFilterClick('moveIn')}
                                                endIcon={<ExpandMoreIcon />}
                                            >
                                                Date Available
                                            </Button>
                                            <Popover
                                                id={filtersIds.moveIn}
                                                open={openFilters.moveIn}
                                                anchorEl={anchorEl.moveIn}
                                                onClose={handleFilterClose('moveIn')}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    className={classes.filterFormContainer}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <Grid
                                                            container
                                                            justifyContent='space-around'
                                                        >
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                variant='inline'
                                                                label='Move In Date'
                                                                autoOk
                                                                format='MMMM dd, yyyy'
                                                                id='moveIn-picker'
                                                                value={
                                                                    searchParams.get('moveIn')
                                                                        ? new Date(
                                                                              parseInt(
                                                                                  searchParams.get(
                                                                                      'moveIn',
                                                                                  ) as string,
                                                                              ),
                                                                          )
                                                                        : Date.now()
                                                                }
                                                                minDate={new Date(Date.now())}
                                                                onChange={handleFilterDateChange}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'add date',
                                                                }}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                            </Popover>
                                        </Grid>
                                        {isMobile && (
                                            <Grid item>
                                                <ToggleButtonGroup
                                                    color='primary'
                                                    value={tab}
                                                    exclusive
                                                    onChange={handleTabChange}
                                                    aria-label='View Options'
                                                >
                                                    <ToggleButton
                                                        value={Tabs.Map}
                                                        style={{ padding: 3, fontSize: 11 }}
                                                    >
                                                        Map
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value={Tabs.List}
                                                        style={{ padding: 3, fontSize: 11 }}
                                                    >
                                                        List
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {!loading
                                        ? isMobile && tab === Tabs.List && <PropertiesList />
                                        : ''}

                                    {!loading ? !isMobile && <PropertiesList /> : ''}
                                </Grid>
                            </Grid>
                            {/* MAP */}
                            {isMobile && tab === Tabs.Map && <Map />}
                            {!isMobile && <Map />}
                        </Grid>
                    </section>
                </PageContent>
            </MainLayout>
        </>
    );
});
