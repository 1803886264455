import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogContent } from '@material-ui/core';
import { useStyles } from './styles';
import { Inquiry } from 'persona';
import { PersonaEnvironment, PersonaTemplateId } from '../../appConfig';
import { RootStoreContext } from '../../global/storeContext';
import { Field } from 'persona/dist/lib/interfaces';

interface PersonaFields {
    firstname: string;
    lastname: string;
    dob: { year: number; month: number; day: number };
}

export const PersonaModal: React.FC<{
    person: PersonaFields;
    openVerificationModal: boolean;
    closeVerificationModal: () => void;
    onVerificationComplete: () => void;
}> = observer(
    ({ person, openVerificationModal, onVerificationComplete, closeVerificationModal }) => {
        const classes = useStyles();
        const { idVerificationStore, uiStore } = useContext(RootStoreContext);

        const closeModal = () => {
            onVerificationComplete();
        };

        return (
            <>
                <Dialog
                    open={openVerificationModal}
                    onClose={closeVerificationModal}
                    maxWidth='sm'
                    fullWidth={true}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                    className={classes.root}
                >
                    <DialogContent>
                        <Inquiry
                            templateId={PersonaTemplateId}
                            environment={PersonaEnvironment}
                            onLoad={() => {
                                console.log('Loaded Persona Verification inline');
                                console.log(person);
                            }}
                            onComplete={({ inquiryId, status, fields }) => {
                                // Inquiry completed. Optionally tell your server about it.
                                if (status === 'completed') {
                                    closeModal();
                                    uiStore.hideLoading();
                                }
                            }}
                            onEvent={(name, meta) => {
                                switch (name as any) {
                                    case 'start': {
                                        if (meta && meta.inquiryId) {
                                            idVerificationStore
                                                .updatePersonaIDVerification(
                                                    meta.inquiryId as string,
                                                )
                                                .then(() => {
                                                    // setInquiryId(meta.inquiryId as string);
                                                });
                                        }
                                        break;
                                    }
                                    case 'complete': {
                                        if (meta && meta.inquiryId) {
                                            const inquiryId = meta.inquiryId as string;
                                            const fields = meta.fields as Record<string, Field>;
                                            const idFirstname = fields['name-first']!
                                                .value as string;
                                            const idLastname = fields['name-last']!.value as string;
                                            const idDOB = (
                                                fields['birthdate']!.value as string
                                            ).split('-');

                                            const profileIDMatch =
                                                (!person.firstname ||
                                                    person.firstname.toLowerCase() ===
                                                        idFirstname.toLowerCase()) &&
                                                (!person.lastname ||
                                                    person.lastname.toLowerCase() ===
                                                        idLastname.toLowerCase()) &&
                                                (!person.dob ||
                                                    (person.dob.year == Number(idDOB[0]) &&
                                                        person.dob.month == Number(idDOB[1]) &&
                                                        person.dob.day == Number(idDOB[2])));

                                            if (!profileIDMatch) {
                                                idVerificationStore
                                                    .declineIDVerification(inquiryId)
                                                    .then(() => {
                                                        uiStore.error(
                                                            'ID Verification Failed.<br />Your First Name, Last Name, Date of Birth and Gender must match the information on your ID',
                                                        );
                                                    });
                                            }
                                        }
                                    }
                                }
                            }}
                            onError={(error) => {
                                console.error(`Identity Verification Error`, error);
                            }}
                            onCancel={({ inquiryId, sessionToken }) =>
                                console.log('Identity Verification Cancelled')
                            }
                            fields={{
                                nameFirst: person.firstname,
                                nameLast: person.lastname,
                                birthdate: `${person.dob.year}-${person.dob.month}-${person.dob.day}`,
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </>
        );
    },
);
