import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { BankAccountComponent } from './bank';
import { WalletComponent } from '../wallet/walletView';
import { TabComponent, TabSection } from '../uiComponents/tabs';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import BankOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IFormattedBankConnection {
    timestamp: number;
    institution: string;
    institutionId: string;
}

export const ConnectedAccounts: React.FC<{
    connections: IFormattedBankConnection[];
    onRemoveConnection: () => void;
}> = observer(({ connections, onRemoveConnection }) => {
    return (
        <>
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={8}>
                    {connections?.map((method, index) => {
                        return (
                            <Grid
                                container
                                justifyContent='space-between'
                                key={`${method?.institution}-${index}`}
                            >
                                <Grid item xs={10}>
                                    <Box style={{ display: 'flex' }}>
                                        <BankOutlinedIcon fontSize='large' />
                                        <Box ml={4}>
                                            <Typography
                                                variant='body1'
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {method?.institution}
                                            </Typography>
                                            <Typography variant='caption'>
                                                {format(method.timestamp, 'PPp')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={2}>
                                    <Typography>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            color='secondary'
                                            onClick={onRemoveConnection}
                                        >
                                            Remove
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
});

export const FinancialsComponent: React.FC = observer(() => {
    const { connectBankAccount } = useFlags();
    const sections: TabSection[] = [
        {
            key: 'Wallet',
            title: 'Transactions',
            component: <WalletComponent />,
        },
        {
            key: 'Bank',
            title: 'Bank',
            component: <BankAccountComponent />,
            hide: !connectBankAccount,
        },
    ];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TabComponent
                        sections={sections}
                        onSubmit={() => {}}
                        hideStepperButtons={true}
                        disableSave
                    />
                </Grid>
            </Grid>
        </>
    );
});
